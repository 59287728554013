import React, {forwardRef, useRef, useEffect, useState, useMemo} from 'react';
import {motion, useScroll, useMotionValueEvent} from 'framer-motion';

const AlwaysVisibleCanvas = forwardRef(({style, ...props}, ref) => {
    const dummyRef = useRef();
    const canvasRef = useRef();
    const {scrollY} = useScroll();
    const [isFixed, setIsFixed] = useState(false);

    useMotionValueEvent(scrollY, 'change', latest => {
        if (canvasRef.current && dummyRef.current) {
            const dummyTop = dummyRef.current.getBoundingClientRect().top + window.scrollY;
            if (latest + 20 > dummyTop && !isFixed) {
                setIsFixed(true);
            } else if (latest + 20 <= dummyTop && isFixed) {
                setIsFixed(false);
            }
        }
    });

    useEffect(() => {
        if (isFixed && canvasRef.current && dummyRef.current) {
            const rect = canvasRef.current.getBoundingClientRect();
            dummyRef.current.style.height = rect.height + 'px';
            dummyRef.current.style.width = rect.width + 'px';
        } else {
            dummyRef.current.style.height = '0px';
            dummyRef.current.style.width = '0px';
        }
    }, [isFixed]);

    const canvas = useMemo(() => {
        const canvasStyle = {
            ...(style || {}),
            position: isFixed ? 'fixed' : 'static',
            top: isFixed ? '20px' : 'auto',
            left: isFixed ? `${canvasRef.current?.getBoundingClientRect().left}px` : 'auto',
            width: isFixed ? `${canvasRef.current?.getBoundingClientRect().width}px` : 'auto',
            height: isFixed ? `${canvasRef.current?.getBoundingClientRect().height}px` : 'auto',
            minWidth: isFixed ? '0' : '100%',
            minHeight: isFixed ? '0' : '100%',
            maxWidth: '100%',
            maxHeight: isFixed ? '50vh' : '100%',
            zIndex: isFixed ? 1000 : 'auto'
        };

        return (
            <>
                <div
                    ref={dummyRef}
                    key="dummy"
                    style={{
                        width: '0px',
                        height: '0px',
                        position: 'relative',
                        zIndex: -1
                    }}
                />
                <motion.canvas
                    ref={node => {
                        canvasRef.current = node;
                        if (typeof ref === 'function') {
                            ref(node);
                        } else if (ref) {
                            ref.current = node;
                        }
                    }}
                    key="canvas"
                    style={canvasStyle}
                    layout
                    {...props}
                />
            </>
        );
    }, [style, props, isFixed]);

    return canvas;
});

export default AlwaysVisibleCanvas;
