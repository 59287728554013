import React, {useState, useRef, useEffect, useLayoutEffect, useMemo, forwardRef, useCallback} from 'react';
import {createPortal} from 'react-dom';
import {AnimatePresence, motion} from 'framer-motion';
import {useColorMode} from '@chakra-ui/react';
import MemoryStatsComponent from 'react-memorystats';

import FontPicker from 'react-fontpicker-ts';
import 'react-fontpicker-ts/dist/index.css';
// import SrtEditor from './SrtEditor.js';
import {throttle, debounce} from 'lodash';
import {
    Button,
    Input as ChakraInput,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    IconButton,
    ButtonGroup,
    Box,
    Flex,
    HStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Tooltip,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton
} from '@chakra-ui/react';
import {Progress as ProgressChakra} from '@chakra-ui/react';
import {Reorder, useDragControls} from 'framer-motion';
import {Alert, AlertIcon, AlertTitle, AlertDescription, CloseButton} from '@chakra-ui/react';
// Chakra editable
import {Editable, EditablePreview, EditableInput} from '@chakra-ui/react';
import {
    FaFileArchive,
    FaUndo,
    FaFileUpload,
    FaFileDownload,
    FaImage,
    FaMusic,
    FaVideo,
    FaPlay,
    FaPause,
    FaVolumeUp,
    FaVolumeDown,
    FaVolumeMute,
    FaArrowsAlt,
    // Airdrop
    FaFileExport
} from 'react-icons/fa';
import {MdEditNote} from 'react-icons/md';
import {IoMdText} from 'react-icons/io';
import {GiBackForth} from 'react-icons/gi';
import {MdDragHandle, MdStop} from 'react-icons/md';

import AlwaysVisibleCanvas from './AlwaysVisibleCanvas.js';

// Icon for visibility of item
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai';

import DropboxSync from './DropboxSync.js';

import moment from 'moment';
import styled from 'styled-components';
import axios from 'axios';
import SrtGenerator, {Row, Column} from './SrtGenerator/SrtGeneratorWordsBatch_new';
import {showDirectoryPicker, clearStorage} from './custom-file-system-polyfill-new.js';
import {audioRender} from './CreateSyncedAudio.js';
import CreateSrtRenderer from './CreateSrtRenderer.js';
import renderWithWebCodecAPI from './renderWithWebCodecAPI.js';
import cameraShake from './utils/cameraShake.js';
import {useStateWithIndexedDB, clearStorage as clearStorageIDB} from './useStateWithIndexedDB.js';
import subtitles from './Subtitles.js';
import canvasImage from './CanvasImage.js';

import {convertTo} from './convertTo.js';

import {realTimeLog, RealTimeLogger} from './RealTimeLogger.js';

import createVideoFrameGenerator from './createVideoFrameGeneratorWebCodec.js';

import JSZip from 'jszip';
import {fileOpen} from 'browser-fs-access';

import FileInputDragNDrop from './FileInputDragNDrop.js';

import {TimelineDemo} from './Timeline.js';

import HighlightedLog from './HighlightedLog.js';

import withDragAndDrop from './withDragAndDrop.js';

import VideoTimeline from './timeline/VideoTimeline.js';

const ShareFileButton = ({folderHandle, fileName}) => {
    const handleShare = async () => {
        try {
            // Get the file from the folder handle
            const fileHandle = await folderHandle.getFileHandle(fileName);
            const file = await fileHandle.getFile();

            // Ensure the device supports the Web Share API with files
            if (navigator.canShare && navigator.canShare({files: [file]})) {
                await navigator.share({
                    files: [file],
                    title: 'Share Video',
                    text: 'Check out this video!'
                });
                console.log('File shared successfully.');
            } else {
                console.error('Sharing not supported on this device.');
            }
        } catch (error) {
            console.error('Error sharing file:', error);
        }
    };

    return (
        <Button onClick={handleShare} colorScheme="blue">
            Share
        </Button>
    );
};

const TemporaryButton = forwardRef(({active, onClick, children, ...props}, ref) => {
    return (
        <AnimatePresence>
            {active && (
                <motion.div
                    ref={ref}
                    initial={{opacity: 0, width: 0}}
                    animate={{opacity: 1, width: 'auto'}}
                    exit={{opacity: 0, width: 0}}
                >
                    <Button onClick={onClick} {...props}>
                        {children}
                    </Button>
                </motion.div>
            )}
        </AnimatePresence>
    );
});

const NumberInput = ({value, onChange, style, ...props}) => {
    const [tempValue, setTempValue] = useState(value);
    const [lastValidValue, setLastValidValue] = useState(value);

    useEffect(() => {
        // If valid number, run onChange
        if (!isNaN(parseFloat(tempValue))) {
            onChange({target: {value: parseFloat(tempValue)}});
            setLastValidValue(parseFloat(tempValue));
        }
    }, [tempValue]);

    useEffect(() => {
        setTempValue(value);
        setLastValidValue(value);
    }, [value]);

    return (
        <ChakraInput
            type="text"
            value={tempValue}
            onChange={e => {
                setTempValue(e.target.value);
            }}
            onBlur={e => {
                onChange({target: {value: lastValidValue}});
                setTempValue(lastValidValue);
            }}
            style={{
                // Red if invalid
                ...(isNaN(parseFloat(tempValue))
                    ? {
                          color: 'red',
                          borderColor: 'red',
                          backgroundColor: 'rgba(255, 0, 0, 0.1)',
                          boxShadow: '0 0 0 1px red'
                      }
                    : {}),
                ...(style || {})
            }}
            {...props}
        />
    );
};

const Input = ({value, onChange, type, ...props}) => {
    // If type is number, use NumberInput otherwise use ChakraInput
    if (type === 'number') {
        return <NumberInput value={value} onChange={onChange} {...props} />;
    }

    return (
        <ChakraInput
            type={type}
            value={value}
            onChange={e => {
                onChange({target: {value: e.target.value}});
            }}
            {...props}
        />
    );
};

const RenderButton = ({folderHandle, fileName, handleRender}) => {
    // Only render the button if a file with the name exists in the folder
    // If the button is clicked it should remove the file from the folder and hide the button
    const [visible, setVisible] = useState(false);
    const [progress, setProgress] = useState(0);
    const checkFile = async () => {
        try {
            const videoFileHandle = await folderHandle.getFileHandle(fileName);
            const videoFile = await videoFileHandle.getFile();
            const buffer = new Uint8Array(await videoFile.arrayBuffer()); // Load the video file
            if (buffer.length > 0) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        } catch (e) {
            setVisible(false);
        }

        try {
            const progressFileHandle = await folderHandle.getFileHandle('progress.txt');
            const progressFile = await progressFileHandle.getFile();
            const progressBuffer = new Uint8Array(await progressFile.arrayBuffer());
            const progress = parseInt(new TextDecoder().decode(progressBuffer));
            setProgress(progress);
        } catch (e) {
            setProgress(0);
        }
    };

    useEffect(() => {
        checkFile();
    }, [folderHandle, fileName]);

    useEffect(() => {
        // Listen to event to update visibility
        const handleUpdateVisibility = e => {
            checkFile();
        };

        window.addEventListener('update-video-url', handleUpdateVisibility);

        return () => window.removeEventListener('update-video-url', handleUpdateVisibility);
    }, [visible]);

    const removeFile = async () => {
        try {
            const fileHandle = await folderHandle.getFileHandle(fileName);
            await fileHandle.remove();
            setVisible(false);
        } catch (e) {
            console.error(e);
        }
    };

    const startRender = async () => {
        handleRender();
        setVisible(false);
    };

    return visible ? (
        <>
            <Alert
                status="info"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                width="100%"
            >
                <AlertIcon />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                    Rendered video exists {progress > 0 ? ` (frame ${progress})` : ''}
                </AlertTitle>
                <AlertDescription>Click the button to remove it and render a new video.</AlertDescription>
                <ButtonGroup mt={4}>
                    <Button
                        onClick={() => {
                            removeFile();
                            startRender();
                        }}
                        mt={4}
                        colorScheme="blue"
                    >
                        Restart
                    </Button>
                    <Button onClick={startRender} mt={4} colorScheme="green">
                        Continue
                        <FaVideo style={{marginLeft: '10px'}} />
                    </Button>
                    <Button onClick={removeFile} mt={4} colorScheme="red">
                        Remove
                    </Button>
                </ButtonGroup>
            </Alert>
        </>
    ) : (
        <Button onClick={startRender} colorScheme="green">
            Render video
        </Button>
    );
};

const MemoryStatsToggle = () => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button onClick={() => setOpen(!open)}>Memory stats</Button>
            {open && <MemoryStatsComponent corner="bottomRight" />}
        </>
    );
};

// import YoutubeAuth from './connections/YoutubeAuth.js';

if (window.location.hash === '#test') {
    window.showDirectoryPicker = false; // DDebug
}

const InputWrapper = styled(HStack)`
    margin: 10px 0;
        flex-wrap: wrap;
        width: 100%;
        > * {
            flex: 1 1 0%;
            min-width: 200px;
        }
    }
`;

// applyKeyframes(item.settings, item.settings.keyframes || [], currentTime);
const applyKeyframes = (staticSettings, keyframes, currentTime) => {
    const settings = staticSettings;
    /* Keyframes is an array of objects
    Each object is like this:
    {
        time: 0,
        x: 0,
        y: 0,
        scale: 1,
        rotate: 0,
        opacity: 1,
        shakeMultiplier: 0,
        currentTimeMultiplier: 10000,
        smoothness: 0.005
    }
    It will smoothly start approaching the keyframe values as the currentTime approaches the keyframe time in a deterministic way
    When the keyframe time is reached, the values will be exactly the keyframe values and stay there until the next keyframe
    */
    if (keyframes.length === 0) return settings;

    const keyframe = keyframes.find(k => k.time <= currentTime);
    if (!keyframe) return settings;

    const nextKeyframe = keyframes.find(k => k.time > currentTime);

    const timeDiff = nextKeyframe ? nextKeyframe.time - keyframe.time : 1;
    const timeProgress = (currentTime - keyframe.time) / timeDiff;

    const interpolate = (start, end, progress) => start + (end - start) * progress;

    const keys = Object.keys(keyframe).filter(k => k !== 'time');
    keys.forEach(key => {
        settings[key] = interpolate(staticSettings[key], keyframe[key], timeProgress);
    });

    return settings;
};

const RenderedVideo = ({folderHandle, videoName, width, height}) => {
    const [videoUrl, setVideoUrl] = useState(null);

    useEffect(() => {
        const updateVideoUrl = async (e = {}) => {
            try {
                if (!folderHandle) return;

                setVideoUrl(null);

                await new Promise(resolve => setTimeout(resolve, 1000));

                if (e.detail) {
                    setVideoUrl(e.detail);
                    return;
                }

                // Get the file handle for the video
                const videoFileHandle = await folderHandle.getFileHandle(videoName);

                // Get the file from the handle
                const videoFile = await videoFileHandle.getFile();

                if (!videoFile) return;

                // Create an object URL for the file
                const url = URL.createObjectURL(videoFile);

                // Set the video URL to render the video
                setVideoUrl(url);

                // Clean up the URL after the component is unmounted or video changes
                // return () => URL.revokeObjectURL(url);
            } catch (e) {
                // console.error(e);
            }
        };

        updateVideoUrl();

        // Subscribe to window event to update the video URL
        window.addEventListener('update-video-url', updateVideoUrl);

        return () => window.removeEventListener('update-video-url', updateVideoUrl);
    }, [folderHandle, videoName]);

    return videoUrl ? (
        <Box>
            <video
                controls
                loop
                playsInline
                width={width}
                height={height}
                style={{
                    width: '400px',
                    height: 'auto',
                    maxWidth: '100%',
                    backgroundColor: 'black'
                }}
            >
                <source src={videoUrl} type="video/mp4" />
            </video>

            <ShareFileButton folderHandle={folderHandle} fileName={videoName} />

            <Button
                colorScheme="red"
                onClick={() => {
                    // Remove the video file
                    folderHandle.removeEntry(videoName);
                    setVideoUrl(null);
                }}
            >
                Remove rendered video
            </Button>
        </Box>
    ) : null;
};

const Logo = styled.h1`
    font-size: 0.5em;
    font-family: monospace;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: white;
    margin: 0 16px;
    img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        cursor: pointer;
    }
`;

const updateColor = debounce(color => {
    requestAnimationFrame(() => {
        localStorage.setItem('darkColor', color);
        document.body.style.setProperty('--dark-color', color);
    });
}, 100);

const Header = ({children}) => {
    const colorRef = useRef(null);

    return (
        <Logo>
            <Tooltip label="Set theme color" placement="right">
                <img
                    onClick={() => {
                        colorRef.current.click();
                    }}
                    onDoubleClick={() => {
                        localStorage.removeItem('darkColor');
                        window.location.reload();
                    }}
                    src={
                        // Base url with root path
                        window.location.pathname === '/srt/' ? '/srt/favicon.png' : '/favicon.png'
                    }
                />
            </Tooltip>{' '}
            <input
                key="color"
                ref={colorRef}
                type="color"
                style={{display: 'none'}}
                onChange={e => {
                    updateColor(e.target.value);
                }}
            />
            SKRP/BETA | SRT VIDEO CREATOR
        </Logo>
    );
};

//import CanvasWorkerTest from './test/canvasWorkerTest.js';

window.mainFont = 'veteran_typewriterregular';
let mainFont = window.mainFont;

// import createVideoFrameGeneratorFFMPEG from './createVideoFrameGenerator.js';
/*let createVideoFrameGeneratorPromise;
if (window.VideoDecoder) {
    console.log('Using WebCodec API')
    createVideoFrameGeneratorPromise = import("./createVideoFrameGeneratorWebCodec.js");
} else {
    console.log('Using FFMPEG')
    createVideoFrameGeneratorPromise = import("./createVideoFrameGenerator.js");
}

const createVideoFrameGenerator = async (...args) => {
    const { default: createVideoFrameGenerator } = await createVideoFrameGeneratorPromise;
    return createVideoFrameGenerator(...args);
};*/

// Repeat the text and align it absolute left and right with ellipsis in middle

const speed = 40 / (60 / 25) / 5;
const spacing = 100; // 50

const offsetCount = 2; //5;

const EllipsisMiddle = ({children, fillWidth = false, startLength = 10, endLength = 10}) => {
    const text = children.toString();
    const textLength = text.length;
    const containerRef = useRef(null);
    const textRef = useRef(null);
    const [computedLengths, setComputedLengths] = useState({start: startLength, end: endLength});

    useEffect(() => {
        const handleSizing = () => {
            if (fillWidth && containerRef.current && textRef.current) {
                const containerWidth = containerRef.current.parentNode.offsetWidth;
                textRef.current.style.display = 'inline';
                const charWidth = textRef.current.offsetWidth / textLength;
                textRef.current.style.display = 'none';

                const availableWidth = containerWidth - 3 * charWidth; // Width of "..."
                const totalChars = Math.floor(availableWidth / charWidth);

                const dynamicStartLength = Math.floor(totalChars / 2);
                const dynamicEndLength = totalChars - dynamicStartLength;

                setComputedLengths({
                    start: dynamicStartLength,
                    end: dynamicEndLength
                });
            }
        };

        handleSizing();
        window.addEventListener('resize', handleSizing);

        return () => window.removeEventListener('resize', handleSizing);
    }, [fillWidth, textLength]);

    const {start, end} = computedLengths;

    if (textLength <= start + end) {
        return <span ref={containerRef}>{text}</span>;
    }

    const startText = text.slice(0, start);
    const endText = text.slice(textLength - end);

    return (
        <span ref={containerRef}>
            <span ref={textRef} style={{display: 'none'}}>
                {text}
            </span>
            {startText}...{endText}
        </span>
    );
};

const hashBlob = async (blob, algorithm = 'SHA-256') => {
    const arrayBuffer = await blob.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest(algorithm, arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
};

const applyDropboxLink = async e => {
    let url = e.target.value;
    if (!url) return;
    if (url.includes('dropbox.com')) {
        url = url.replace('www.dropbox.com', 'dl.dropbox.com').replace('?dl=0', '?raw=1');
    }
    try {
        const response = await axios.get(url, {responseType: 'arraybuffer'});
        const filename = new URL(url).pathname.split('/').pop().split('?')[0];
        return new File([response.data], filename, {
            type: response.headers['content-type']
        });
    } catch (e) {}
};

const SrtTextEditor = ({item, file, style}) => {
    const [folderHandle, setFolderHandleState] = useStateWithIndexedDB('folderHandle');
    const [text, setText] = useState('');
    const [active, setActive] = useState(false);

    useEffect(() => {
        const reader = new FileReader();
        reader.onload = e => {
            setText(e.target.result);
        };
        reader.readAsText(file);
    }, [file]);

    // Read file contents
    // Write using updateHandler
    const updateHandler = async newText => {
        const blob = new Blob([newText], {type: 'text/plain'});

        const event = new CustomEvent('use-srt', {
            detail: () =>
                new Promise(resolve => {
                    resolve([file, blob]);
                })
        });
        window.dispatchEvent(event);
    };

    return (
        <>
            <Button colorScheme="green" onClick={() => setActive(true)} style={{marginBottom: '10px'}} size="sm">
                Edit SRT
            </Button>
            {active && (
                <SrtGenerator
                    open={active}
                    closeModal={() => setActive(false)}
                    audioFileNameProp={item.settings.audioFileName}
                    data={item.settings}
                    folderHandle={folderHandle}
                />
            )}
        </>
    );
};

const useCurrentTime = () => {
    // Listen to window event
    const [currentTime, setCurrentTime] = useState(0);

    useEffect(() => {
        const handleCurrentTime = e => {
            setCurrentTime(e.detail.currentTime);
        };

        window.addEventListener('current-time', handleCurrentTime);

        return () => window.removeEventListener('current-time', handleCurrentTime);
    }, []);

    return {currentTime};
};

const KeyframeSettings = ({settings, onChange}) => {
    const {currentTime} = useCurrentTime();
    // Has options to goto next keyframe, previous keyframe, add keyframe, remove keyframe
    // Goes to keyframes by using window.dispatchEvent(new CustomEvent('set-current-time', {detail: {currentTime: 10, name: 'set-current-time'}}));
    // Adds keyframes by adding to settings keyframes array
    // Removes keyframes by removing from settings keyframes array

    const handleAddKeyframe = () => {
        const keyframes = settings.keyframes || [];
        const newKeyframe = {
            time: currentTime,
            x: settings.x,
            y: settings.y,
            rotate: settings.rotate,
            scale: settings.scale,
            opacity: settings.opacity,
            shakeMultiplier: settings.shakeMultiplier,
            currentTimeMultiplier: settings.currentTimeMultiplier,
            smoothness: settings.smoothness
        };
        keyframes.push(newKeyframe);
        onChange({...settings, keyframes});
    };

    const handleRemoveKeyframe = () => {
        const keyframes = settings.keyframes || [];
        const currentTime = settings.currentTime;
        const newKeyframes = keyframes.filter(k => k.time !== currentTime);
        onChange({...settings, keyframes: newKeyframes});
    };

    const handleNextKeyframe = () => {
        const keyframes = settings.keyframes || [];
        const currentTime = settings.currentTime;
        const nextKeyframe = keyframes.find(k => k.time > currentTime);
        if (nextKeyframe) {
            window.dispatchEvent(new CustomEvent('set-current-time', {detail: {currentTime: nextKeyframe.time}}));
        }
    };

    const handlePreviousKeyframe = () => {
        const keyframes = settings.keyframes || [];
        const currentTime = settings.currentTime;
        const previousKeyframe = keyframes.filter(k => k.time < currentTime).pop();
        if (previousKeyframe) {
            window.dispatchEvent(new CustomEvent('set-current-time', {detail: {currentTime: previousKeyframe.time}}));
        }
    };

    return (
        <div>
            <textarea
                style={{
                    color: 'black',
                    backgroundColor: 'white',
                    width: '100%',
                    height: '200px',
                    fontFamily: mainFont,
                    fontSize: '16px',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid black',
                    resize: 'none'
                }}
                value={JSON.stringify(settings.keyframes || [], null, 2)}
                onChange={e => {
                    try {
                        const keyframes = JSON.parse(e.target.value);
                        onChange({...settings, keyframes});
                    } catch (e) {
                        console.error(e);
                    }
                }}
            />
            <Button colorScheme="green" onClick={handleAddKeyframe} size="sm">
                Add keyframe
            </Button>
            <Button colorScheme="red" onClick={handleRemoveKeyframe} size="sm">
                Remove keyframe
            </Button>
            <Button colorScheme="green" onClick={handleNextKeyframe} size="sm">
                Next keyframe
            </Button>
            <Button colorScheme="green" onClick={handlePreviousKeyframe} size="sm">
                Previous keyframe
            </Button>
        </div>
    );
};

const itemTypes = {
    image: (file, settings, setSettings) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        const remove = () => URL.revokeObjectURL(img.src);

        return {
            img,
            settings,
            remove,
            settingsComponent: ({settings}) => (
                <>
                    <ShakeSettings
                        settings={settings}
                        onChange={newSettings => {
                            Object.assign(settings, newSettings);
                            setSettings(settings);
                        }}
                    />
                    <Settings settings={settings} onChange={setSettings} />
                </>
            )
        };
    },
    audio: async (
        file,
        settings,
        setSettings,
        setStatus,
        setVideoLength,
        setDuration,
        FPS,
        folderHandle,
        resetFolderHandle,
        width,
        height,
        videoLength
    ) => {
        const audioSrc = URL.createObjectURL(file);

        setStatus('Fetching audio...');
        const audioPlayer = await audioRender.fetchAudio(audioSrc, setStatus);

        (async () => {
            const audioDuration = await audioRender.getDuration();
            console.log('Setting video length to audio duration', audioDuration);
            setVideoLength(Math.ceil(audioDuration));
            setDuration(Math.ceil(audioDuration));

            setStatus('Audio loaded');

            file.duration = Math.ceil(audioDuration);

            // audioPlayer.panner.pan.value = Math.min(1, Math.max(-1, settings.pan || 0));
            // audioPlayer.volume.value = Math.max(-12, Math.min(12, settings.volume || 0.5));
        })();

        const remove = () => {
            //  audioRender.removeAudio(audioSrc);
            URL.revokeObjectURL(audioSrc);
        };

        window.addEventListener('audio/pause', () => audioRender.pause());

        return {
            audioSrc,
            audioFile: file,
            remove,
            settingsComponent: function AudioSettings() {
                const [srtGeneratorOpen, setSrtGeneratorOpen] = useState(false);
                const [pan, setPan] = useState(Math.min(1, Math.max(-1, settings.pan || 0)));
                const [volume, setVolume] = useState(Math.min(1, Math.max(0, settings.volume || 0.5)));

                return (
                    <div>
                        <div>
                            {/* Pan */}
                            {/*<InputGroup size="sm">
                                <InputLeftAddon children="Pan" />
                                <Slider
                                    value={pan}
                                    min={-1}
                                    max={1}
                                    step={0.1}
                                    onDoubleClick={() => {
                                        setPan(0);
                                        setSettings({...settings, pan: 0});
                                        audioPlayer.panner.pan.value = 0;
                                    }}
                                    onChange={value => {
                                        setPan(value);
                                        setSettings({...settings, pan: parseFloat(value)});
                                        audioPlayer.panner.pan.value = value;
                                    }}
                                >
                                    <SliderTrack>
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb />
                                </Slider>
                                <InputRightAddon children={pan.toFixed(1)} />
                            </InputGroup>*/}

                            {/* Volume */}
                            {/*<InputGroup size="sm" mt={4}>
                                <InputLeftAddon children="Volume" />
                                <Slider
                                    value={volume}
                                    min={-12}
                                    max={12}
                                    step={0.1}
                                    onDoubleClick={() => {
                                        setVolume(0.5);
                                        setSettings({...settings, volume: 0.5});
                                        audioPlayer.volume.value = 0.5;
                                    }}
                                    onChange={value => {
                                        setVolume(value);
                                        setSettings({...settings, volume: parseFloat(value)});
                                        audioPlayer.volume.value = Math.max(-12, Math.min(12, value));
                                    }}
                                >
                                    <SliderTrack>
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb />
                                </Slider>
                                <InputRightAddon children={volume.toFixed(1)} />
                            </InputGroup>
                            <hr />*/}
                        </div>
                        <div>
                            <Button
                                size="sm"
                                colorScheme="green"
                                onClick={() => {
                                    setSrtGeneratorOpen(true);
                                    // Pause playback (not audio)
                                    window.dispatchEvent(new CustomEvent('pause', {detail: {name: 'pause'}}));
                                }}
                            >
                                Open SRT generator
                            </Button>
                            {srtGeneratorOpen && (
                                <SrtGenerator
                                    open={srtGeneratorOpen}
                                    closeModal={() => setSrtGeneratorOpen(false)}
                                    audioSrc={audioSrc}
                                    audioLength={(videoLength && videoLength.current) || 60}
                                    folderHandle={folderHandle}
                                />
                            )}
                        </div>
                    </div>
                );
            }
        };
    },
    /*video: async (
        file,
        settings,
        setSettings,
        setStatus,
        setVideoLength,
        setDuration,
        FPS,
        folderHandle,
        resetFolderHandle,
        width,
        height
    ) => {
        const videoSrc = URL.createObjectURL(file);
        const videoFrameGenerator = await createVideoFrameGenerator(
            videoSrc,
            FPS,
            folderHandle,
            resetFolderHandle,
            width,
            height,
            (...args) => setStatus(args.join(' '))
        );

        const activeFrame = {current: null};

        videoFrameGenerator.setLoopReverse(settings.loopReverse);

        const remove = () => videoFrameGenerator.remove();

        videoFrameGenerator.onFrame(blob => {
            activeFrame.current = blob;
        });

        return {
            videoFrameGenerator,
            activeFrame,
            videoSrc,
            settings,
            remove,
            settingsComponent: ({settings}) => (
                <>
                    <Button
                        colorScheme={settings.loopReverse ? 'gray' : 'green'}
                        leftIcon={<GiBackForth />}
                        onClick={() => {
                            setSettings({...settings, loopReverse: !settings.loopReverse});
                            videoFrameGenerator.setLoopReverse(!settings.loopReverse);
                        }}
                    >
                        {settings.loopReverse ? 'Disable' : 'Enable'} loop reverse
                    </Button>
                    <Settings settings={settings} onChange={setSettings} />
                </>
            )
        };
    },*/
    realtimeVideo: async (
        file,
        settings,
        setSettings,
        setStatus,
        setVideoLength,
        setDuration,
        FPS,
        folderHandle,
        resetFolderHandle,
        widthWrong,
        heightWrong,
        videoLength
    ) => {
        let activeFrame = {current: null};
        const videoSrc = URL.createObjectURL(file);
        const video = document.createElement('video');
        video.src = videoSrc;
        video.loop = true;
        video.muted = true;
        video.autoplay = true;
        video.playsInline = true;
        video.preload = 'auto';

        const {width, height} = (await new Promise(resolve => {
            video.onloadedmetadata = () => {
                resolve({
                    width: video.videoWidth,
                    height: video.videoHeight
                });
            };
        })) || {width: 1920, height: 1080};

        video.width = width;
        video.height = height;

        video.width = width * settings.scale;
        video.height = height * settings.scale;

        const remove = () => {
            video.pause();
            URL.revokeObjectURL(videoSrc);
        };

        let item = {};

        item = Object.assign(item, {
            video,
            videoSrc,
            settings,
            remove,
            activeFrame,
            settingsComponent: ({settings}) => (
                <>
                    <ShakeSettings
                        settings={settings}
                        onChange={newSettings => {
                            Object.assign(settings, newSettings);
                            setSettings(settings);
                        }}
                    />
                    <Settings
                        settings={settings}
                        onChange={newSettings => {
                            Object.assign(settings, newSettings);
                            setSettings(settings);
                            video.width = width * settings.scale;
                            video.height = height * settings.scale;
                        }}
                    />
                </>
            )
        });

        const videoFrameGenerator = await createVideoFrameGenerator(
            videoSrc,
            width,
            height,
            (...args) => console.log(...args),
            {stop: false}
        );

        videoFrameGenerator.onFrame(blob => {
            activeFrame.current = blob;
        });

        item.videoFrameGenerator = videoFrameGenerator;

        return item;
    },
    srt: (file, settings, setSettings) => {
        let item = {};

        const srtSrc = URL.createObjectURL(file);
        const srtRender = new CreateSrtRenderer(srtSrc, {
            updateSrt: newText => {
                const blob = new Blob([newText], {type: 'text/plain'});

                const event = new CustomEvent('use-srt', {
                    detail: () =>
                        new Promise(resolve => {
                            resolve([file, blob]);
                        })
                });
                window.dispatchEvent(event);
            }
        });

        const remove = () => URL.revokeObjectURL(srtSrc);

        item = Object.assign(item, {
            srtRender,
            srtSrc,
            settings,
            remove,
            settingsComponent: ({settings}) => {
                return (
                    <>
                        <Button
                            colorScheme="green"
                            onClick={() => {
                                const currentLine = srtRender.getLastText();
                                const newLine = prompt('Enter new line', currentLine);
                                if (newLine && newLine !== currentLine) {
                                    srtRender.setText(newLine);
                                }
                            }}
                        >
                            Change current line
                        </Button>
                        <Button
                            colorScheme="green"
                            onClick={() => {
                                const a = document.createElement('a');
                                a.href = srtSrc;
                                a.download = 'subtitles.srt';
                                a.click();
                            }}
                        >
                            Download SRT
                        </Button>

                        <div style={{margin: '10px 0'}}>
                            <SrtTextEditor
                                item={item}
                                file={file}
                                style={{
                                    color: 'black',
                                    backgroundColor: 'white',
                                    width: '100%',
                                    height: '200px',
                                    fontFamily: mainFont,
                                    fontSize: '16px',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid black',
                                    resize: 'none'
                                }}
                            />
                        </div>

                        <Settings settings={settings} onChange={setSettings} />

                        <SrtSettings settings={settings} onChange={setSettings} />
                    </>
                );
            }
        });

        return item;
    }
};

const colorMap = {
    image: 'blue',
    audio: 'purple',
    video: 'pink',
    realtimeVideo: 'teal',
    srt: 'orange'
};

const BetaLabel = styled.span`
    position: relative;
    &::before {
        content: 'Beta';
        position: absolute;
        bottom: 110%;
        left: 10%;
        background-color: red;
        color: white;
        padding: 0.25em 0.5em;
        border-radius: 0.25em;
        font-size: 10px;
    }
`;

const iconMap = {
    image: <FaImage />,
    audio: <FaMusic />,
    video: <FaVideo />,
    realtimeVideo: <FaPlay />,
    srt: <IoMdText />
};

const ItemBox = forwardRef(({active, settingsComponent, item, setAccordionIndex, children, ...props}, ref) => {
    const SettingsComponent = settingsComponent;
    const [isHighlighted, setIsHighlighted] = useState(false);
    const dragControls = useDragControls();
    const scrollRef = useRef();

    useEffect(() => {
        // Scroll to if active and wasnt active before
        if (active && scrollRef.current) {
            // Scroll smoothly to element at top with offset
            return;
            setTimeout(() => {
                console.log('scrolling to', scrollRef.current);
                const top = scrollRef.current.getBoundingClientRect().top + window.scrollY;
                console.log('top', top);
                document.querySelector('html').scrollTo({
                    top: top - 20,
                    behavior: 'smooth'
                });
            }, 500);
        }
    }, [active]);

    return (
        <AccordionItem
            // Q: Should we use the item.id as the key?
            // A: Yes, because the item.id is unique and will not change
            key={item.id}
            as={Reorder.Item}
            layout={dragControls.layout}
            drag
            dragControls={dragControls}
            dragListener={false}
            ref={ref}
            value={item}
            borderWidth="1px"
            borderRadius="md"
            overflow="hidden"
            my={4}
            mx={2}
            style={{
                boxShadow: isHighlighted ? '0 0 0 2px rgba(0,0,0, 0.6)' : 'none',
                position: 'relative',
                opacity: item.settings?.hidden ? 0.25 : 1
                // backgroundColor: '#171136'
            }}
            disabled={!item.ready}
            // If screen width is 770px or more, max width is 50vw
            maxW="40vw"
            // If screen width is 770px or less, max width is 100vw
            maxW={{base: '100vw', md: '40vw'}}
            {...props}
        >
            <h2 ref={scrollRef}>
                <AccordionButton
                    px={3}
                    py={3}
                    my={0}
                    _hover={
                        isHighlighted
                            ? {
                                  bg: 'rgba(0, 0, 0, 0.5)'
                              }
                            : {
                                  bg: 'rgba(0, 0, 0, 0.25)'
                              }
                    }
                >
                    <Flex
                        flex="1"
                        textAlign="left"
                        justifyContent="space-between"
                        style={{margin: 0, width: 'calc(100% - 24px)'}}
                    >
                        <HStack spacing="12px">
                            <IconButton
                                as="span"
                                icon={<MdDragHandle />}
                                size="sm"
                                onMouseDown={e => e.stopPropagation()}
                                cursor="grab"
                                onMouseEnter={e => {
                                    setAccordionIndex(null);
                                    setIsHighlighted(true);
                                }}
                                onMouseLeave={e => {
                                    setIsHighlighted(false);
                                }}
                                onTouchStart={e => {
                                    e.stopPropagation();
                                    dragControls.start(e);
                                    setAccordionIndex(null);
                                }}
                                onPointerDown={e => {
                                    e.stopPropagation();
                                    dragControls.start(e);
                                }}
                            />

                            <IconButton
                                as="span"
                                colorScheme={colorMap[item.type]}
                                icon={iconMap[item.type]}
                                size="sm"
                            />
                            <IconButton
                                as="span"
                                icon={item.settings?.hidden ? <AiFillEyeInvisible /> : <AiFillEye />}
                                size="sm"
                                onClick={e => {
                                    e.stopPropagation();
                                    item.setSettings({...item.settings, hidden: !item.settings?.hidden});
                                    // Unfocus
                                    document.activeElement.blur();
                                }}
                            />
                            <strong
                                style={{
                                    opacity: 0.45,
                                    display: 'block',
                                    marginRight: 'auto',
                                    width: '100%',
                                    padding: '0 12px',
                                    whiteSpace: 'pre-line',
                                    alignSelf: 'center'
                                }}
                            >
                                <RealTimeLogger type={item.id}>
                                    <EllipsisMiddle fillWidth={true}>
                                        {item.file && item.file.name
                                            ? item.file.name.replace(/ ?\(.*\)/, '')
                                            : !item.ready && !item.file
                                              ? 'No file'
                                              : 'Loading...'}
                                    </EllipsisMiddle>{' '}
                                    <br />
                                    {item.file && item.file.name && item.file.name.includes('(') && (
                                        <i style={{opacity: 0.5}}>
                                            {item.file.name.match(/\(.*\)/)[0].replace(/ /g, ':')}
                                        </i>
                                    )}
                                </RealTimeLogger>
                            </strong>
                        </HStack>
                        <AccordionIcon
                            w={6}
                            h={6}
                            _expanded={{
                                transform: 'rotate(90deg)'
                            }}
                            style={{
                                alignSelf: 'center'
                            }}
                        />
                    </Flex>
                </AccordionButton>
            </h2>
            <AccordionPanel py={4} px={4}>
                {children}
                {SettingsComponent && <SettingsComponent settings={item.settings} setSettings={item.setSettings} />}
            </AccordionPanel>
        </AccordionItem>
    );
});

const FileInput = ({onChange, hasFile}) => {
    const inputRef = useRef();
    return (
        <span style={{position: 'relative', overflow: 'hidden', cursor: 'pointer'}}>
            <Tooltip label={hasFile ? 'Has set file' : 'Add file'} placement="top">
                <IconButton
                    colorScheme={hasFile ? 'gray' : 'green'}
                    icon={<FaFileUpload />}
                    onClick={() => inputRef.current.click()}
                    style={{cursor: 'pointer'}}
                />
            </Tooltip>
            <input
                type="file"
                ref={inputRef}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    cursor: 'pointer',
                    zIndex: -1
                }}
                onChange={onChange}
            />
        </span>
    );
};

const determineFileType = name => {
    if (name.endsWith('.mp3') || name.endsWith('.wav')) return 'audio';
    if (name.endsWith('.mp4')) return 'video';
    if (name.endsWith('.srt')) return 'srt';

    return 'image';
};

let fileInstanceStore = new Map();

const AddFiles = React.memo(
    ({
        setStatus,
        setVideoLength,
        setDuration,
        FPS,
        folderHandle,
        resetFolderHandle,
        width,
        height,
        items,
        setItems,
        videoLength,

        // Handle dropped files and after they are added depending on file type run the onFilesProcessed callback
        droppedFiles,
        onFilesProcessed
    }) => {
        const [accordionIndex, setAccordionIndex] = useState(null);
        const hasInitiatedRef = useRef(false);
        const itemsRef = useRef(items);

        useEffect(() => {
            itemsRef.current = items;
        }, [items]);

        const loadPersistentState = async () => {
            if (!folderHandle || hasInitiatedRef.current) return;
            hasInitiatedRef.current = true;
            let newItems = [];

            for (const type of Object.keys(itemTypes)) {
                try {
                    const subFolder = await folderHandle.getDirectoryHandle(type, {create: true});
                    for await (const [name, fileHandle] of subFolder.entries()) {
                        try {
                            if (
                                fileHandle.kind === 'file' &&
                                !name.endsWith('-settings.json') &&
                                !name.startsWith('.')
                            ) {
                                let settings = await loadSettings(subFolder, name);
                                const file = await fileHandle.getFile();
                                let item = await createItem(type, file, settings, subFolder);
                                newItems.push(item);
                            }
                        } catch (e) {
                            console.log('Error loading persistent state', e);
                        }
                    }
                } catch (e) {
                    console.log('Error loading persistent state', e);
                }
            }

            newItems = newItems.sort((a, b) => (a.settings.order || 0) - (b.settings.order || 0));
            setItems(newItems);
        };

        useEffect(() => {
            if (folderHandle) loadPersistentState();
        }, [folderHandle]);

        const loadSettings = async (subFolder, name) => {
            let settings = {...fallbackSettings};
            try {
                const settingsFileHandle = await subFolder.getFileHandle(`${name}-settings.json`, {create: false});
                const settingsFile = await settingsFileHandle.getFile();
                const settingsText = await settingsFile.text();
                settings = {...settings, ...JSON.parse(settingsText)};
            } catch (e) {
                await saveSettings(subFolder, name, settings);
            }
            return settings;
        };

        const saveSettings = async (subFolder, name, settings) => {
            const settingsFileHandle = await subFolder.getFileHandle(`${name}-settings.json`, {create: true});
            const settingsWritable = await settingsFileHandle.createWritable();
            await settingsWritable.write(JSON.stringify(settings));
            await settingsWritable.close();
        };

        const createItem = async (type, file, settings, subFolder) => {
            const setSettings = throttle(
                async newSettings => {
                    setItems(prevItems =>
                        prevItems.map(item => (item.id === file.name ? {...item, settings: newSettings} : item))
                    );
                    await saveSettings(subFolder, file.name, newSettings);
                },
                10,
                {trailing: true}
            );

            let item = {
                id: file.name,
                type,
                file,
                settings,
                ready: true,
                setSettings
            };

            if (itemTypes[type]) {
                item = {
                    ...item,
                    ...(await itemTypes[type](
                        file,
                        settings,
                        setSettings,
                        setStatus,
                        setVideoLength,
                        setDuration,
                        FPS,
                        folderHandle,
                        resetFolderHandle,
                        width,
                        height,
                        videoLength
                    ))
                };
            }

            return item;
        };

        const handleFileChange = async (e, index, item) => {
            if (!folderHandle) return;
            let file = e.target && e.target.files ? e.target.files[0] : await applyDropboxLink(e);
            if (!file) return;

            if (item && item.type === 'audio' && file.type.indexOf('audio') === -1) {
                file = await convertTo(file, 'wav', (args, progress) => realTimeLog(args.join(' '), item.id, progress));
                setTimeout(() => {
                    realTimeLog(null, item.id);
                }, 1000);
            }

            // If MOV, convert to MP4
            if (file && file.name && file.name.toLowerCase().endsWith('.mov')) {
                file = await convertTo(file, 'mp4', (args, progress) => realTimeLog(args.join(' '), item.id, progress));
                setTimeout(() => {
                    realTimeLog(null, item.id);
                }, 1000);
            }

            if (file && file.name && ['wav', 'mp3', 'mp4'].includes(file.name.split('.').pop())) {
                const duration = await new Promise(resolve => {
                    const audio = new Audio(URL.createObjectURL(file));
                    audio.onloadedmetadata = () => {
                        resolve(audio.duration);
                    };
                });
                const [name, ext] = file.name.split('.');
                file = new File([file], `${name} (${moment.utc(duration * 1000).format('HH mm ss')}).${ext}`, {
                    type: file.type
                });
            }

            const updatedItems = [...itemsRef.current];
            item = item || {type: determineFileType(file.name)};

            if (item.file) {
                try {
                    const folder = await folderHandle.getDirectoryHandle(item.type, {create: false});
                    await folder.removeEntry(item.file.name, {recursive: true});
                    await folder.removeEntry(`${item.file.name}-settings.json`, {recursive: true});
                    item.remove && item.remove();
                } catch (e) {
                    console.log('Error removing file', e);
                }
            }

            const folder = await folderHandle.getDirectoryHandle(item.type, {create: true});
            const fileHandle = await folder.getFileHandle(file.name, {create: true});
            const writable = await fileHandle.createWritable();
            await writable.write(new Uint8Array(await file.arrayBuffer()));
            await writable.close();

            const settings = await loadSettings(folder, file.name);
            const newItem = await createItem(item.type, file, settings, folder);

            if (index !== undefined) {
                updatedItems[index] = newItem;
            } else {
                updatedItems.push(newItem);
            }

            setItems(updatedItems);
        };

        const handleRemove = async index => {
            if (!folderHandle) return;
            const updatedItems = [...items];
            const itemToRemove = updatedItems[index];
            const folder = await folderHandle.getDirectoryHandle(itemToRemove.type, {create: false});
            try {
                await folder.removeEntry(itemToRemove.file.name, {recursive: true});
                await folder.removeEntry(`${itemToRemove.file.name}-settings.json`, {recursive: true});
            } catch (e) {
                console.log('Error removing file', e);
            }
            setItems(updatedItems.filter((_, i) => i !== index));
        };
        useEffect(() => {
            const handler = async e => {
                if (typeof e.detail === 'function') {
                    // Existing code for handling function case

                    const [oldFile, newFile] = await e.detail();
                    const item = itemsRef.current.find(item => item.file === oldFile);
                    if (!item) return;

                    newFile.name = item.file.name;
                    const detail = {
                        id: item.id,
                        file: newFile
                    };

                    if (item.file) {
                        try {
                            const folder = await folderHandle.getDirectoryHandle(item.type, {create: false});
                            await folder.removeEntry(item.file.name, {recursive: true});
                            await folder.removeEntry(`${item.file.name}-settings.json`, {recursive: true});
                            item.remove && item.remove();
                        } catch (e) {
                            console.log('Error removing file', e);
                        }
                    }

                    item.file = detail.file;
                    item.ready = true;
                    item.settings = item.settings || {};

                    const folder = await folderHandle.getDirectoryHandle(item.type, {create: true});
                    const fileHandle = await folder.getFileHandle(item.file.name, {create: true});
                    const writable = await fileHandle.createWritable();
                    await writable.write(new Uint8Array(await item.file.arrayBuffer()));
                    await writable.close();
                    const settingsFileHandle = await folder.getFileHandle(`${item.file.name}-settings.json`, {
                        create: true
                    });
                    const settingsWritable = await settingsFileHandle.createWritable();
                    await settingsWritable.write(JSON.stringify(item.settings));
                    await settingsWritable.close();

                    const setSettings = newSettings => {
                        setItems(prevItems =>
                            prevItems.map(item => (item.id === detail.id ? {...item, settings: newSettings} : item))
                        );
                    };

                    const updatedItems = [...itemsRef.current];
                    const itemIndex = updatedItems.findIndex(item => item.id === detail.id);
                    updatedItems[itemIndex] = {
                        ...updatedItems[itemIndex],
                        ...(await itemTypes[item.type](
                            item.file,
                            item.settings,
                            setSettings,
                            setStatus,
                            setVideoLength,
                            setDuration,
                            FPS,
                            folderHandle,
                            resetFolderHandle,
                            width,
                            height
                        ))
                    };

                    setItems(updatedItems);
                } else {
                    const {srt: srtData, audioFileName, data} = e.detail;
                    const blob = new Blob([srtData], {type: 'text/plain'});
                    let item = {
                        type: 'srt',
                        file: blob,
                        ready: true,
                        settings: fallbackSettings,
                        audioFileName,
                        data
                    };
                    const updatedItems = [...itemsRef.current];
                    const hash = await hashBlob(blob);
                    const fileName = `${hash}.srt`;
                    item.id = fileName;
                    item.file.name = fileName;
                    const setSettings = newSettings => {
                        setItems(prevItems =>
                            prevItems.map(prevItem =>
                                prevItem.id === item.id ? {...prevItem, settings: newSettings} : prevItem
                            )
                        );
                    };
                    const folder = await folderHandle.getDirectoryHandle(item.type, {create: true});
                    const fileHandle = await folder.getFileHandle(fileName, {create: true});
                    const writable = await fileHandle.createWritable();
                    await writable.write(new Uint8Array(await blob.arrayBuffer()));
                    await writable.close();
                    const settingsFileHandle = await folder.getFileHandle(`${fileName}-settings.json`, {create: true});
                    const settingsWritable = await settingsFileHandle.createWritable();
                    await settingsWritable.write(
                        JSON.stringify({
                            ...item.settings,
                            audioFileName,
                            ...data
                        })
                    );
                    await settingsWritable.close();
                    updatedItems.push(item);
                    if (itemTypes[item.type]) {
                        updatedItems[updatedItems.length - 1] = {
                            ...updatedItems[updatedItems.length - 1],
                            ...(await itemTypes[item.type](item.file, item.settings, setSettings))
                        };
                    }

                    setItems(updatedItems);
                }
            };
            window.addEventListener('use-srt', handler);
            return () => window.removeEventListener('use-srt', handler);
        }, [folderHandle]);

        useEffect(() => {
            const handler = e => {
                const item = e.detail;
                // Add copy to name
                //item.file = new File([item.file], `Copy of ${item.file.name}`);
                const updatedItems = [...items];
                const newItem = {...item, id: Date.now()};
                updatedItems.push(newItem);
                setItems(updatedItems);
            };
            window.addEventListener('duplicate-item', handler);

            return () => window.removeEventListener('duplicate-item', handler);
        }, [items]);

        useEffect(() => {
            // Dropped files
            if (droppedFiles.length) {
                droppedFiles.forEach(async file => {
                    // Check if file is already added to prevent duplicates
                    if (fileInstanceStore.has(file)) return;
                    fileInstanceStore.set(file, true);

                    const type = determineFileType(file.name);
                    const settings = {...fallbackSettings};
                    const subFolder = await folderHandle.getDirectoryHandle(type, {create: true});
                    const item = await createItem(type, file, settings, subFolder);
                    setItems([...items, item]);

                    setTimeout(() => {
                        handleFileChange({target: {files: [file]}}, null, item);
                        if (onFilesProcessed) onFilesProcessed();
                    }, 100);
                });
            }
        }, [droppedFiles]);

        return (
            <>
                {Object.keys(itemTypes).map(type => (
                    <Tooltip key={type} label={`Add ${type}`} placement="top">
                        <IconButton
                            colorScheme={colorMap[type]}
                            icon={iconMap[type]}
                            my={4}
                            mx={2}
                            onClick={() => setItems([...items, {id: Date.now(), type, ready: false, file: null}])}
                        />
                    </Tooltip>
                ))}
                <Box maxW="800px">
                    <Accordion
                        key="accordion"
                        as={Reorder.Group}
                        values={items}
                        onReorder={newItemsOrder => {
                            // Compare new order with old order and update settings file for each item that has changed its order
                            newItemsOrder.forEach(async (newItem, index) => {
                                const oldItem = itemsRef.current.find(item => item.id === newItem.id);
                                if (oldItem.settings.order !== index) {
                                    const folder = await folderHandle.getDirectoryHandle(oldItem.type, {create: true});
                                    await saveSettings(folder, oldItem.id, {...oldItem.settings, order: index});
                                }
                            });
                            setItems(newItemsOrder);
                        }}
                        index={accordionIndex}
                        onChange={setAccordionIndex}
                        allowToggle={true}
                    >
                        {items.map((item, index) => (
                            <FileInputDragNDrop
                                use={ItemBox}
                                onDrop={e => handleFileChange(e, index, item)}
                                key={item.id}
                                settingsComponent={item.settingsComponent}
                                item={item}
                                setAccordionIndex={setAccordionIndex}
                                active={accordionIndex === index}
                            >
                                <HStack spacing="12px">
                                    <Button colorScheme="red" onClick={() => handleRemove(index)}>
                                        Remove item
                                    </Button>
                                    {item.ready && (
                                        <Button
                                            colorScheme="gray"
                                            onClick={() => {
                                                const a = document.createElement('a');
                                                a.href = URL.createObjectURL(item.file);
                                                a.download = item.file.name;
                                                a.click();
                                            }}
                                        >
                                            Download
                                        </Button>
                                    )}
                                    <FileInput hasFile={item.file} onChange={e => handleFileChange(e, index, item)} />
                                </HStack>

                                <Input
                                    variant="filled"
                                    type="text"
                                    my={4}
                                    placeholder="Dropbox link"
                                    onBlur={e => handleFileChange(e, index, item)}
                                />
                            </FileInputDragNDrop>
                        ))}
                    </Accordion>
                </Box>
                {/*<Tooltip label="Import ZIP" placement="top">
                    <>
                        <IconButton
                            colorScheme="green"
                            icon={<FaFileArchive />}
                            onClick={() => document.getElementById('zip-import').click()}
                            my={4}
                            mx={2}
                        />

                        <input
                            id="zip-import"
                            type="file"
                            style={{display: 'none'}}
                            onChange={handleZipImport}
                            accept=".zip"
                        />
                    </>
                </Tooltip>*/}
            </>
        );
    }
);

async function clearDirectory(directoryHandle) {
    if (!directoryHandle.values) return;
    for await (const entry of directoryHandle.values()) {
        if (entry.name.startsWith('.')) continue;
        if (entry.kind === 'file') {
            const fileHandle = await directoryHandle.getFileHandle(entry.name);
            await fileHandle.remove();
        } else if (entry.kind === 'directory') {
            const dirHandle = await directoryHandle.getDirectoryHandle(entry.name);
            await clearDirectory(dirHandle);
            await dirHandle.remove();
        }
    }
}
// document.title = 'FPS: ' + FPS;

const DurationEdit = ({value, min, max, onChange}) => {
    const inputRef = useRef();
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(value);

    const handleBlur = () => {
        setIsEditing(false);
        onChange({target: {value: inputValue}});
    };

    useEffect(() => {
        if (isEditing) inputRef.current.focus();
    }, [isEditing]);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    return (
        <div style={{margin: '20px 0', cursor: 'pointer'}}>
            {isEditing ? (
                <div>
                    <input
                        type="range"
                        ref={inputRef}
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                        min={min}
                        max={max}
                    />
                    <input
                        type="text"
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                        onBlur={handleBlur}
                    />
                </div>
            ) : (
                <span onClick={() => setIsEditing(true)}>{moment.utc(value * 1000).format('mm:ss')}</span>
            )}
        </div>
    );
};

const useFolderHandle = () => {
    const [checkingFolderHandle, setCheckingFolderHandle] = useState(true);
    const [folderHandle, setFolderHandleState] = useStateWithIndexedDB('folderHandle');

    const [config, setConfigReal] = useState({
        duration: 60,
        FPS: 25,
        width: 1080,
        height: 1080
    });

    const setConfig = newConfig => {
        if (checkingFolderHandle) return;
        setConfigReal({...config, ...newConfig});
    };

    const setFolderHandle = async newFolderHandle => {
        setFolderHandleState(newFolderHandle);
    };

    // Reset folder handle
    const resetFolderHandle = () => {
        setFolderHandle(null);
    };

    useEffect(() => {
        if (folderHandle) {
            setTimeout(() => {
                setCheckingFolderHandle(false);
            }, 1000);
        }
    }, [folderHandle]);

    // Load settings from folder handle
    useEffect(() => {
        if (!folderHandle || checkingFolderHandle) return;
        (async () => {
            // Read settings.txt
            try {
                const settingsFileHandle = await folderHandle.getFileHandle('settings.txt');
                const settingsFile = await settingsFileHandle.getFile();
                const settingsText = await settingsFile.text();
                const settings = JSON.parse(settingsText);
                setConfig(settings);
            } catch (e) {
                console.log('Error loading settings', e);
            }
        })();
    }, [folderHandle, checkingFolderHandle]);

    // Save settings to folder handle
    useEffect(() => {
        if (!folderHandle || checkingFolderHandle) return;
        (async () => {
            // Write settings.txt
            const settingsFileHandle = await folderHandle.getFileHandle('settings.txt', {create: true});
            const settingsWritable = await settingsFileHandle.createWritable();
            await settingsWritable.write(JSON.stringify(config));
            await settingsWritable.close();
        })();
    }, [folderHandle, config, checkingFolderHandle]);

    return [
        folderHandle,
        setFolderHandle,
        resetFolderHandle,
        // Config
        config,
        // Set config
        setConfig,
        checkingFolderHandle
    ];
};

const normalizeByMinMax = (value, min, max) => {
    if (value < min) min = value;
    if (value > max) max = value;
    return value / max;
};

const smoothValue = (value, currentTime, duration, smoothness) => {
    const smooth = cameraShake(currentTime * 1000 + value, 12, 0.01);
    return smooth.scale;
};

const useFileStorage = (name, rootHandle, setStatus, initialSettings, converter) => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (!rootHandle) return;
        (async () => {
            const folderHandle = await rootHandle.getDirectoryHandle(name, {create: true});
            if (!folderHandle) return;

            const loadedFiles = [];
            for await (const entry of await folderHandle.values()) {
                if (entry.name.startsWith('.') || entry.name.endsWith('-settings.txt')) continue;
                const fileHandle = await folderHandle.getFileHandle(entry.name);
                const val = await converter(fileHandle);
                val.name = entry.name;
                val.settings =
                    JSON.parse(
                        await (await folderHandle.getFileHandle(`${entry.name}-settings.txt`)).getFile().text()
                    ) || initialSettings;
                loadedFiles.push(val);
            }
            setFiles(loadedFiles);
        })();
    }, [rootHandle]);

    const addFile = async file => {
        if (!rootHandle) {
            setStatus('No folder selected');
            return;
        }
        const folderHandle = await rootHandle.getDirectoryHandle(name, {create: true});
        const fileHandle = await folderHandle.getFileHandle(file.name, {create: true});
        const settingsFileHandle = await folderHandle.getFileHandle(`${file.name}-settings.txt`, {create: true});
        const settingsWritable = await settingsFileHandle.createWritable();
        await settingsWritable.write(JSON.stringify(initialSettings));
        await settingsWritable.close();

        const writable = await fileHandle.createWritable();
        await writable.write(file);
        await writable.close();

        const value = await converter(fileHandle);
        value.name = file.name;
        value.settings = initialSettings;
        setFiles(prev => [...prev, value]);
    };

    const removeFile = async index => {
        if (!rootHandle) {
            setStatus('No folder selected');
            return;
        }
        const file = files[index];
        const folderHandle = await rootHandle.getDirectoryHandle(name, {create: true});
        await folderHandle.removeEntry(file.name);
        await folderHandle.removeEntry(`${file.name}-settings.txt`);
        setFiles(prev => prev.filter((_, i) => i !== index));
    };

    const updateFileSettings = async (index, settings) => {
        if (!rootHandle) {
            setStatus('No folder selected');
            return;
        }
        const file = files[index];
        const folderHandle = await rootHandle.getDirectoryHandle(name, {create: true});
        const settingsFileHandle = await folderHandle.getFileHandle(`${file.name}-settings.txt`);
        const writable = await settingsFileHandle.createWritable();
        await writable.write(JSON.stringify(settings));
        await writable.close();
        setFiles(prev => {
            const newFiles = [...prev];
            newFiles[index] = {...newFiles[index], settings};
            return newFiles;
        });
    };

    return [files, addFile, removeFile, updateFileSettings];
};

const useLocalStorage = (key, initialValue) => {
    const [value, setValue] = useState(() => {
        const savedValue = localStorage.getItem(key);
        try {
            return savedValue ? JSON.parse(savedValue) : initialValue;
        } catch (e) {
            return initialValue;
        }
    });

    useEffect(() => {
        let val = value || initialValue;
        try {
            val = value.map(item => ({...item, ready: false}));
        } catch (e) {}

        localStorage.setItem(key, JSON.stringify(val));
    }, [value]);

    return [value, setValue];
};

// Resize container
const ResizeContainer = styled.div`
    position: relative;
    resize: vertical;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
    max-width: 50vw;
    background: rgba(0, 0, 0, 0.2);
`;

// This element can be resized by dragging the bottom right corner, the size will be stored in localstorage by the name prop
const Resizer = ({name, children, ...props}) => {
    const ref = useRef();

    useEffect(() => {
        const size = localStorage.getItem(name + '-size');
        if (size) {
            const {width, height} = JSON.parse(size);
            // ref.current.style.width = width + 'px';
            ref.current.style.height = height + 'px';
        }
    }, []);

    useEffect(() => {
        // Apply resizeObserver
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                console.log('width', entry.contentRect.width);
                console.log('height', entry.contentRect.height);
                localStorage.setItem(
                    name + '-size',
                    JSON.stringify({width: entry.contentRect.width, height: entry.contentRect.height})
                );
            }
        });
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <ResizeContainer ref={ref} {...props}>
            {children}
        </ResizeContainer>
    );
};

const yPositionMap = new Map();

const findCanvas = doc => {
    const canvases = doc.querySelectorAll('canvas');
    if (canvases.length > 0) {
        return canvases[0];
    }
    const iframes = doc.querySelectorAll('iframe');
    for (let i = 0; i < iframes.length; i++) {
        try {
            const canvas = findCanvas(iframes[i].contentDocument);
            if (canvas) {
                return canvas;
            }
        } catch (e) {}
    }
    return null;
};

const CanvasWithPiP = forwardRef(({render, onClick, ...props}, ref) => {
    const [isStreaming, setIsStreaming] = useState(false);
    const [isPiP, setIsPiP] = useState(false);
    const [videoStream, setVideoStream] = useState(null);
    const [video, setVideo] = useState(null);
    const canvasRef = ref;

    const startStreaming = async () => {
        if (isStreaming) return;
        const canvas = findCanvas(document) || canvasRef.current;
        if (!canvas) {
            console.error('No canvas found');
            return;
        }
        const stream = canvas.captureStream();
        const video = document.createElement('video');
        video.srcObject = stream;
        video.playsInline = true;
        video.controls = true;
        video.play();
        video.style.position = 'fixed';
        video.style.top = '10px';
        video.style.right = '10px';
        video.style.width = '400px';
        video.style.height = 'auto';
        video.style.maxWidth = 'calc(100% - 20px)';
        video.style.zIndex = '10000';
        video.style.backgroundColor = 'white';
        video.style.borderRadius = '10px';
        video.style.boxShadow = '0px 0px 10px rgba(0, 0, 0, 0.5)';
        document.body.appendChild(video);

        setVideo(video);
        setVideoStream(stream);
        setIsStreaming(true);
        render.current();
    };

    const requestPiP = async () => {
        if (!isStreaming || !video) return;
        try {
            await video.requestPictureInPicture();
            setIsPiP(true);
        } catch (error) {
            console.error('Failed to enter Picture-in-Picture mode:', error);
        }
    };

    const stopPiP = async () => {
        if (!isPiP) return;
        try {
            await document.exitPictureInPicture();
        } catch (error) {
            console.error('Failed to exit Picture-in-Picture mode:', error);
        }
        setIsPiP(false);
    };

    const stopStreaming = () => {
        if (!isStreaming) return;
        videoStream.getTracks().forEach(track => track.stop());
        video.remove();
        setVideoStream(null);
        setVideo(null);
        setIsStreaming(false);
        setIsPiP(false);
    };

    useEffect(() => {
        if (!canvasRef.current) return;
        // On streaming remove canvas from dom
        // On pip, hide video
        if (isStreaming) {
            canvasRef.current.style.opacity = 0;
        } else {
            canvasRef.current.style.opacity = 1;
        }

        if (video) {
            if (isPiP) {
                video.style.display = 'none';
            } else {
                video.style.display = 'block';
            }
        }
    }, [isStreaming, isPiP]);

    useEffect(() => {
        return () => {
            stopStreaming();
        };
    }, []);

    return (
        <>
            <AlwaysVisibleCanvas {...props} ref={canvasRef} onClick={onClick} />

            <AdvancedOptions
                startStreaming={startStreaming}
                requestPiP={requestPiP}
                stopPiP={stopPiP}
                stopStreaming={stopStreaming}
                isStreaming={isStreaming}
                isPiP={isPiP}
            />
        </>
    );
});

const AdvancedOptions = ({startStreaming, requestPiP, stopPiP, stopStreaming, isStreaming, isPiP}) => {
    const [advancedToggle, setAdvancedToggle] = useState(false);

    return (
        <div style={{margin: '20px', position: 'absolute', top: 0, right: 0}}>
            <Button onClick={() => setAdvancedToggle(!advancedToggle)}>A</Button>
            {advancedToggle && (
                <div>
                    <Button onClick={startStreaming} isDisabled={isStreaming}>
                        {isStreaming ? 'Streaming' : 'Start Streaming'}
                    </Button>
                    <Button onClick={requestPiP} isDisabled={!isStreaming || isPiP}>
                        Request PiP
                    </Button>
                    <Button onClick={stopPiP} isDisabled={!isPiP}>
                        Stop PiP
                    </Button>
                    <Button onClick={stopStreaming} isDisabled={!isStreaming}>
                        Stop Streaming
                    </Button>
                </div>
            )}
        </div>
    );
};

const getDefaultFlushThreshold = () => {
    return 1000;
};

const getDefaultUpdateRate = () => {
    return 250;
};

const VideoRenderer = ({droppedFiles, onFilesProcessed}) => {
    const youtubeUploadRef = useRef();

    const {colorMode, toggleColorMode} = useColorMode();
    const itemsRef = useRef([]);
    const [items, setItems] = useState([]); //useLocalStorage('items', []);
    useEffect(() => {
        itemsRef.current = items;
    }, [items]);
    useEffect(() => {
        colorMode === 'light' && toggleColorMode();
    }, [colorMode]);

    const srtShakeSettingsRef = useRef({
        currentTimeMultiplier: 10000,
        shakeMultiplier: -2,
        smoothness: 0.005
    });

    const [statusProgress, setStatusProgress] = useState(0); // 0-1
    const [status, setStatusState] = useState(['Ready']);
    const setStatus = newStatus => {
        if (typeof newStatus === 'function') {
            newStatus = newStatus(status);
        }
        setStatusState(prev => {
            return [...prev.slice(-100), newStatus];
        });
    };
    // const [currentTime, setCurrentTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isAutoRendering, setIsAutoRendering] = useState(true);
    const videoFrameRef = useRef(null);
    const [currentTime, setCurrentTimeState] = useState(0);
    const setCurrentTime = newTime => {
        if (newTime < 0) newTime = 0;
        if (newTime > videoLengthRef.current) newTime = videoLengthRef.current;
        setCurrentTimeState(newTime);
    };

    const currentTimeRef = useRef(currentTime);
    //const currentTime = currentTimeRef.current;
    // const setCurrentTime = time => (currentTimeRef.current = time);
    const canvasRef = useRef(null);
    const smallCanvasRef = useRef(null);
    const currentCanvasRef = useRef(null);
    const ctx = useRef(null);
    const smallCtx = useRef(null);

    const renderCanvasRef = useRef(null);

    const volumeRef = useRef(1);

    const [folderHandle, setFolderHandle, resetFolderHandle, config, setConfig, checkingFolderHandle] =
        useFolderHandle();

    let {width, height, scale, duration, FPS} = {
        width: config.width ? parseInt(config.width, 10) : 1080,
        height: config.height ? parseInt(config.height, 10) : 1080,
        scale: config.scale ? parseFloat(config.scale) : 1,
        duration: config.duration ? parseFloat(config.duration) : 0,
        FPS: config.FPS ? parseInt(config.FPS, 10) : 25
    };

    const setFPS = newFPS => {
        setConfig({...config, FPS: newFPS});
    };

    const setDuration = newDur => {
        setConfig({...config, duration: newDur});
    };

    const setVideoLength = newLength => {
        videoLengthRef.current = newLength;
        setConfig({...config, duration: newLength});
    };
    const videoLength = duration;
    const videoLengthRef = useRef(videoLength);
    videoLengthRef.current = videoLength;

    width = Math.max(width, 1);
    height = Math.max(height, 1);

    const canvasScale = scale;

    const [stopState, setStopState] = useState(false);
    const [updateRate, setUpdateRate] = useLocalStorage('updateRate', getDefaultUpdateRate());
    const [flushThreshold, setFlushThreshold] = useLocalStorage('flushThreshold', getDefaultFlushThreshold());

    useEffect(() => {
        // if width or height is not 1080 or scale is not 1, reset their values
        if (!width) {
            setConfig({...config, width: 1080});
        }
        if (!height) {
            setConfig({...config, height: 1080});
        }
        if (!scale) {
            setConfig({...config, scale: 1});
        }
    }, [width, height, scale]);

    const canvas = useMemo(
        () => (
            <Resizer name="video-canvas" style={{position: 'relative'}}>
                <CanvasWithPiP
                    key="video-canvas"
                    render={renderCanvasRef}
                    ref={canvasRef}
                    width={width}
                    height={height}
                    onClick={e => {
                        const srtItem = itemsRef.current.find(item => item.type === 'srt');
                        if (srtItem) {
                            const currentLine = srtItem.srtRender.getLastText();
                            const newLine = prompt('Enter new line', currentLine);
                            if (newLine && newLine !== currentLine) {
                                srtItem.srtRender.setText(newLine);
                            }
                        }
                    }}
                    style={{
                        minWidth: '100%',
                        minHeight: '100%',
                        cursor: 'pointer',
                        objectFit: 'contain',
                        objectPosition: 'center',
                        border: '1px solid rgba(0,0,0,0.4)'
                    }}
                />
            </Resizer>
        ),
        [width, height]
    );

    const smallCanvas = useMemo(
        () =>
            canvasScale !== '1' &&
            canvasScale !== 1 && (
                <canvas
                    key="small-canvas"
                    ref={smallCanvasRef}
                    width={width * parseFloat(canvasScale)}
                    height={height * parseFloat(canvasScale)}
                    style={{width: '100%', height: 'auto'}}
                />
            ),
        [canvasScale]
    );

    currentCanvasRef.current = smallCanvasRef.current || canvasRef.current;

    useLayoutEffect(() => {
        const savedTime = localStorage.getItem('currentTime');
        if (savedTime) {
            setCurrentTime(parseFloat(savedTime));
        }
    }, [videoLength, duration]);

    useEffect(() => {
        if (currentTime) {
            localStorage.setItem('currentTime', currentTime);
        }
    }, [currentTime]);

    // Subscribe to window event for playing and pausing
    useEffect(() => {
        const playEventHandler = e => {
            if (e.detail && e.detail.name === 'play') {
                setIsPlaying(true);
            }
        };
        window.addEventListener('play', playEventHandler);

        const pauseEventHandler = e => {
            if (e.detail && e.detail.name === 'pause') {
                setIsPlaying(false);
            }
        };
        window.addEventListener('pause', pauseEventHandler);

        return () => {
            window.removeEventListener('play', playEventHandler);
            window.removeEventListener('pause', pauseEventHandler);
        };
    }, [setIsPlaying]);

    // Subscribe to window event for setting currentTime
    useEffect(() => {
        const currentTimeEventHandler = e => {
            if (
                e.detail &&
                e.detail.currentTime &&
                e.detail.name === 'set-current-time' &&
                (e.detail.currentTime || 0) !== currentTime
            ) {
                setCurrentTime(e.detail);
            }
        };
        window.addEventListener('set-current-time', currentTimeEventHandler);

        // Usage: window.dispatchEvent(new CustomEvent('set-current-time', {detail: {currentTime: 10, name: 'set-current-time'}}));

        return () => {
            window.removeEventListener('set-current-time', currentTimeEventHandler);
        };
    }, []);

    // Send event every second with current time
    useEffect(() => {
        window.dispatchEvent(new CustomEvent('current-time', {detail: {currentTime}}));
    }, [currentTime]);

    useEffect(() => {
        currentTimeRef.current = currentTime;
    }, [currentTime]);

    useEffect(() => {
        canvasRef.current.width = width;
        canvasRef.current.height = height;
        ctx.current = canvasRef.current.getContext('2d');
    }, [width, height]);

    useEffect(() => {
        if (smallCanvasRef.current) {
            smallCanvasRef.current.width = width * parseFloat(canvasScale);
            smallCanvasRef.current.height = height * parseFloat(canvasScale);
            smallCtx.current = smallCanvasRef.current.getContext('2d');
        }
    }, [width, height, canvasScale]);

    const renderCanvas = useCallback(
        async (currentTime, stableRender, hq) => {
            if (!currentTime) {
                currentTime = currentTimeRef.current;
            }
            const externalCtx = ctx.current;
            if (!externalCtx) return;

            externalCtx.fillStyle = 'black';
            externalCtx.fillRect(0, 0, width, height);
            const items = itemsRef.current;

            for (const item of items) {
                const {startTime, endTime} = item.settings || {};
                if (currentTime < startTime || currentTime > endTime) continue;
                if (item.settings?.hidden) continue;
                if (!item.ready) continue;

                let {x, y, rotate, scale, opacity, blur} = item.settings;

                mainFont = '46px ' + item.settings.font || mainFont;

                rotate = ((rotate || 0) * 360) / 4;
                x = x || 0;
                y = y || 0;
                scale = scale || 0;
                opacity = opacity || 1;
                blur = blur || 0;

                if (blur > 0) {
                    externalCtx.filter = `blur(${blur}px)`;
                }

                if (item.type === 'video') {
                    try {
                        if (stableRender) {
                            await item.videoFrameGenerator.update(currentTime, hq);
                        } else {
                            item.videoFrameGenerator.update(currentTime, hq);
                        }
                    } catch (e) {
                        console.log('Error updating video frame', e);
                    }

                    const imageShake = cameraShake(
                        srtShakeSettingsRef.current.currentTimeMultiplier * currentTime,
                        srtShakeSettingsRef.current.shakeMultiplier,
                        srtShakeSettingsRef.current.smoothness
                    );

                    if (item.activeFrame && item.activeFrame.current) {
                        await canvasImage({
                            x: imageShake.x + x * (width / 2),
                            y: imageShake.y + y * (height / 2),
                            rotate: imageShake.rotate * 10 + rotate * 2,
                            scale: imageShake.scale,
                            opacity,
                            img: item.activeFrame.current,
                            ctx: externalCtx,
                            width: item.activeFrame.current.width,
                            height: item.activeFrame.current.height,
                            canvasWidth: width,
                            canvasHeight: height
                        });
                    }
                } else if (item.type === 'realtimeVideo') {
                    const imageShake = cameraShake(
                        currentTime * item.settings.currentTimeMultiplier,
                        item.settings.shakeMultiplier,
                        item.settings.smoothness
                    );

                    if (item.video) {
                        const threshold = 0.15;
                        if (hq && folderHandle) {
                            await item.videoFrameGenerator.update(currentTime, hq);
                        } else {
                            item.videoFrameGenerator.update(currentTime, hq);
                        }

                        let frame = null;
                        if (item.activeFrame.current) {
                            frame = item.activeFrame.current;
                        } else {
                            frame = new Image();
                        }

                        await canvasImage({
                            x: imageShake.x + x * (width / 2),
                            y: imageShake.y + y * (height / 2),
                            rotate: imageShake.rotate * 10 + rotate * 2,
                            scale: imageShake.scale + item.settings.scale,
                            opacity,
                            img: frame,
                            ctx: externalCtx,
                            width: frame.width,
                            height: frame.height,
                            canvasWidth: width,
                            canvasHeight: height
                        });
                    }
                } else if (item.type === 'srt') {
                    const {currentTimeMultiplier, shakeMultiplier, smoothness} = Object.assign(
                        {},
                        srtShakeSettingsRef.current,
                        item.settings
                    );
                    let subtitleShakeMain = cameraShake(
                        currentTimeMultiplier * currentTime,
                        shakeMultiplier,
                        smoothness
                    );
                    const subtitleShake = {x: 0, y: 0, rotate: 0, scale: 1};

                    const applyFontStyle = ctx => {
                        ctx.font = `${item.settings.fontSize || 46}px ${item.settings.font || window.mainFont}`;
                        ctx.fillStyle = 'white';
                        ctx.textAlign = 'center';
                        ctx.shadowColor = 'rgba(0,0,0,0.5)';
                        ctx.shadowBlur = 5;
                        ctx.shadowOffsetX = 5;
                        ctx.shadowOffsetY = 5;

                        lineHeight = ctx.measureText('M').width * 2.5;
                    };

                    const prevCount = item.settings.offsetCount || 0;
                    const nextCount = item.settings.offsetCount || 0;

                    let line = item.srtRender.getLine(currentTime);
                    let prevLines = item.srtRender.getTextOffset(currentTime, -prevCount);
                    let nextLines = item.srtRender.getTextOffset(currentTime, nextCount);

                    const allIds = [line && line.id, ...(prevLines || []), ...(nextLines || [])].map(line => line.id);

                    for (const [key] of yPositionMap) {
                        if (!allIds.includes(key)) {
                            //yPositionMap.delete(key);
                        }
                    }

                    let yyTo = subtitleShake.y * 10 + y * (height / 2);
                    let opacityTo = 1;
                    let opacity = yPositionMap.get(line.id)?.opacity || 0;
                    let yy = yPositionMap.get(line.id)?.y || yyTo;
                    yy = yy + (yyTo - yy) / speed;
                    opacity = opacity + (opacityTo - opacity) / speed;
                    yPositionMap.set(line.id, {
                        y: yy,
                        opacity: opacity,
                        line: line
                    });

                    var lineHeight;
                    const mainSubInfo = await subtitles({
                        currentTime,
                        fps: FPS,
                        duration: videoLength,
                        x: subtitleShakeMain.x * 10 + x * (width / 2),
                        y: yy,
                        rotate: subtitleShakeMain.rotate * 100 + rotate * 2,
                        scale: subtitleShakeMain.scale * scale,
                        opacity: opacity,
                        srtRender: item.srtRender,
                        ctx: externalCtx,
                        fps: FPS,
                        settings: item.settings,
                        style: applyFontStyle,
                        canvasWidth: width,
                        canvasHeight: height
                    });

                    if (prevLines) {
                        let offset = -(mainSubInfo?.lineCount * mainSubInfo?.lineHeight) / 2 - spacing;
                        let counter = 0;
                        await prevLines.reduce(async (prev, line) => {
                            await prev;

                            let yyTo = subtitleShake.y * 10 + y * (height / 2) + offset;
                            let opacityTo = 0.8 - counter * 0.2;
                            opacityTo -= Math.max(0, item.settings.opacityOffset || 0);
                            opacityTo = Math.max(0, opacityTo);
                            let opacity = yPositionMap.get(line.id)?.opacity || 0;
                            let yy = yPositionMap.get(line.id)?.y || yyTo;
                            yy = yy + (yyTo - yy) / speed;
                            opacity = opacity + (opacityTo - opacity) / speed;
                            yPositionMap.set(line.id, {
                                y: yy,
                                opacity: opacity,
                                line: line
                            });

                            const subInfo = await subtitles({
                                currentTime,
                                fps: FPS,
                                duration: videoLength,
                                x: subtitleShake.x * 10 + x * (width / 2),
                                y: yy,
                                rotate: subtitleShake.rotate * 100 + rotate * 2,
                                scale: subtitleShake.scale * scale,
                                opacity: opacity,
                                text: line.text,
                                srtRender: item.srtRender,
                                ctx: externalCtx,
                                fps: FPS,
                                settings: item.settings,
                                style: applyFontStyle,
                                canvasWidth: width,
                                canvasHeight: height
                            });

                            offset -= (subInfo?.lineCount * subInfo?.lineHeight) / 2 + spacing;
                            counter++;
                        });
                    }

                    if (nextLines) {
                        let offset = (mainSubInfo?.lineCount * mainSubInfo?.lineHeight) / 2 + spacing;
                        let counter = 0;
                        await nextLines.reduce(async (prev, line) => {
                            await prev;

                            let yyTo = subtitleShake.y * 10 + y * (height / 2) + offset;
                            let opacityTo = 0.8 - counter * 0.2;
                            opacityTo -= Math.max(0, item.settings.opacityOffset || 0);
                            opacityTo = Math.max(0, opacityTo);
                            let opacity = yPositionMap.get(line.id)?.opacity || 0;
                            let yy = yPositionMap.get(line.id)?.y || yyTo;
                            yy = yy + (yyTo - yy) / speed;
                            opacity = opacity + (opacityTo - opacity) / speed;
                            yPositionMap.set(line.id, {
                                y: yy,
                                opacity: opacity,
                                line: line
                            });

                            const subInfo = await subtitles({
                                currentTime,
                                fps: FPS,
                                duration: videoLength,
                                x: subtitleShake.x * 10 + x * (width / 2),
                                y: yy,
                                rotate: subtitleShake.rotate * 100 + rotate * 2,
                                scale: subtitleShake.scale * scale,
                                opacity: opacity,
                                text: line.text,
                                srtRender: item.srtRender,
                                ctx: externalCtx,
                                fps: FPS,
                                settings: item.settings,
                                style: applyFontStyle,
                                canvasWidth: width,
                                canvasHeight: height
                            });

                            offset += (subInfo?.lineCount * subInfo?.lineHeight) / 2 + spacing;
                            counter++;
                        });
                    }
                } else if (item.type === 'image') {
                    const imageShake = cameraShake(
                        currentTime * item.settings.currentTimeMultiplier,
                        item.settings.shakeMultiplier,
                        item.settings.smoothness
                    );

                    await canvasImage({
                        x: imageShake.x + x * (width / 2),
                        y: imageShake.y + y * (height / 2),
                        rotate: imageShake.rotate + rotate * 2,
                        scale: imageShake.scale + scale * 0.5,
                        opacity,
                        img: item.img,
                        ctx: externalCtx,
                        width: item.img.width,
                        height: item.img.height,
                        canvasWidth: width,
                        canvasHeight: height
                    });
                }

                if (blur > 0) {
                    externalCtx.filter = 'none';
                }
            }

            if (smallCtx.current) {
                smallCtx.current.drawImage(
                    canvasRef.current,
                    0,
                    0,
                    width,
                    height,
                    0,
                    0,
                    width * parseFloat(canvasScale),
                    height * parseFloat(canvasScale)
                );
            }
        },
        [width, height, folderHandle, scale, items, videoLength, FPS]
    );

    useEffect(() => {
        renderCanvasRef.current = renderCanvas;
    }, [renderCanvas]);

    useEffect(() => {
        // Render once whenever items change
        renderCanvasRef.current(currentTime, false, false);
    }, [items]);

    useEffect(() => {
        let req;
        if (isPlaying) {
            let lastTime = Date.now();
            const loop = () => {
                req = setTimeout(loop, 1000 / FPS);
                const newTime = currentTimeRef.current + (Date.now() - lastTime) / 1000;
                lastTime = Date.now();
                if (newTime !== currentTimeRef.current) {
                    setCurrentTime(newTime);
                    renderCanvasRef.current(newTime, false, false);
                }
            };
            loop();
        } else {
            // Passively render when not playing
            /*req = setInterval(() => {
                renderCanvasRef.current(currentTimeRef.current, false, false);
            }, 1000 / FPS);*/
        }
        return () => {
            clearTimeout(req);
            clearInterval(req);
        };
    }, [isPlaying, FPS]);

    const handleRender = async () => {
        setIsPlaying(false);
        setIsAutoRendering(false);
        if (canvasRef.current) {
            setStatus('Rendering...');

            const stopState = {stop: false};

            setStopState(stopState);

            // Prepare videos
            await items.reduce(async (prev, item) => {
                await prev;

                if (!item.ready) return;

                if (stopState.stop) {
                    setStatus('Rendering stopped');
                    return;
                }

                if (item.type === 'realtimeVideo') {
                    setStatus('Creating video frame generator...');

                    // Find real video width and height
                    const videoWidth = item.video.videoWidth;
                    const videoHeight = item.video.videoHeight;

                    const scale = item.settings.scale || 1;
                    // Take scale into account
                    // videoWidth * scale,
                    // videoHeight * scale,

                    item.videoFrameGenerator.setArguments({
                        width: videoWidth * scale,
                        height: videoHeight * scale,
                        stopState,
                        setStatus: console.log
                    });
                }
            }, Promise.resolve());

            if (stopState.stop) {
                setStatus('Rendering stopped');
                return;
            }

            const audioUrl = items.find(item => item.type === 'audio')?.audioSrc;
            // setStatus("Rendering audio...");
            // const audioSrc = await audioRender.offlineExport(setStatus, setStatusProgress);

            // Add audio player for testing
            // const audio = document.createElement("audio");
            // audio.src = audioSrc;
            // // audio.volume = 0.5;
            // document.body.appendChild(audio);

            const videoBlob = await renderWithWebCodecAPI(
                folderHandle,
                currentCanvasRef,
                audioUrl,
                renderCanvasRef.current,
                videoLength,
                FPS,
                scale,
                msg => {
                    setStatus(msg);
                },
                stopState,
                // Advanced
                updateRate,
                flushThreshold
            );

            // Upload video
            if (youtubeUploadRef.current) {
                setStatus('Uploading video...');
                youtubeUploadRef.current.upload(
                    videoBlob,
                    // Meta
                    {
                        title: 'Dimensionellt vatten - #12 (version 3)',
                        description: 'Testing uploaded'
                    }
                );

                setStatus('Video uploaded');
            }

            setStopState(false);
        }
    };

    useEffect(() => {
        if (currentTime >= videoLength) setCurrentTime(0);
        if (isPlaying) {
            (async () => {
                const audioCurrentTime = await audioRender.getCurrentTime();
                const threshold = 0.1;
                // audioRender.setVolume(volumeRef.current);

                if (audioCurrentTime > currentTime + threshold || audioCurrentTime < currentTime - threshold) {
                    audioRender.play(currentTime);
                }
            })();
        } else {
            audioRender.pause();
        }
    }, [isPlaying, currentTime]);

    // On current time change, if paused render canvas once
    useEffect(() => {
        if (!isPlaying) {
            renderCanvasRef.current(currentTime, false, false);
            setStatus('Drawing frame... ' + currentTime);
        }
    }, [currentTime]);

    useEffect(() => {
        const handleKeydown = e => {
            if (document.activeElement !== document.body) return;
            let FRAME_DURATION = 5;
            if (e.shiftKey) {
                // Step only one frame
                FRAME_DURATION = 1 / FPS;
            }
            if (e.key === 'ArrowRight') {
                setCurrentTime(prev => prev + FRAME_DURATION);
                e.preventDefault();
            } else if (e.key === 'ArrowLeft') {
                setCurrentTime(prev => Math.max(prev - FRAME_DURATION, 0));
                e.preventDefault();
            } else if (e.key === 'ArrowUp') {
                setCurrentTime(prev => prev + 1);
                e.preventDefault();
            } else if (e.key === 'ArrowDown') {
                setCurrentTime(prev => prev - 1);
                e.preventDefault();
            } else if (e.key === ' ') {
                setIsPlaying(prev => !prev);
                e.preventDefault();
            }
        };
        document.addEventListener('keydown', handleKeydown);
        return () => document.removeEventListener('keydown', handleKeydown);
    }, []);

    useEffect(() => {
        items.forEach(item => {
            if (item.ready && item.type === 'audio') {
                window.dispatchEvent(new CustomEvent('use-audio', {detail: item.audioSrc}));
            }
        });
    }, [items]);

    useEffect(() => {
        window.dispatchEvent(new CustomEvent(isPlaying ? 'audio/play' : 'audio/pause'));
    }, [isPlaying]);

    return (
        <>
            <div style={{opacity: !folderHandle ? 0.5 : 1, pointerEvents: !folderHandle ? 'none' : 'auto'}}>
                <Row>
                    <Header />
                </Row>
                <Row>
                    <Column>
                        <Row direction="column" gap={2}>
                            <Column>
                                {!folderHandle ? (
                                    createPortal(
                                        <SelectFolder
                                            onClick={async () => {
                                                try {
                                                    const handle = await (window.showDirectoryPicker
                                                        ? window.showDirectoryPicker()
                                                        : showDirectoryPicker());
                                                    setFolderHandle(handle);
                                                } catch (e) {
                                                    console.error(e);
                                                }
                                            }}
                                        >
                                            Select Folder
                                        </SelectFolder>,
                                        document.body
                                    )
                                ) : (
                                    <>
                                        <Button
                                            onClick={resetFolderHandle}
                                            style={{marginBottom: '10px', opacity: 0.5}}
                                            mx={2}
                                        >
                                            Change folder
                                        </Button>
                                        <Button
                                            onClick={async () => {
                                                await clearStorage();
                                                await clearStorageIDB();

                                                // Clear local storage, except for key 'openAiToken
                                                Object.keys(localStorage).forEach(key => {
                                                    if (key !== 'openAiToken') {
                                                        localStorage.removeItem(key);
                                                    }
                                                });

                                                alert('Storage cleared');
                                            }}
                                            style={{marginBottom: '10px', opacity: 0.5}}
                                            mx={2}
                                        >
                                            Clear storage
                                        </Button>
                                        {folderHandle && <DropboxSync folderHandle={folderHandle} />}

                                        <div style={{margin: '10px', opacity: 0.5}} mx={2}>
                                            Project folder: {folderHandle.name}
                                        </div>
                                    </>
                                )}
                            </Column>
                            {/* Width (w), height (h), scale (s) all of them inlined */}
                            <Column
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <InputGroup
                                    style={{cursor: 'pointer', width: '250px', maxWidth: '100%', margin: '10px'}}
                                >
                                    <InputLeftAddon>Width:</InputLeftAddon>
                                    <Input
                                        placeholder="1920"
                                        size="md"
                                        type="number"
                                        value={width}
                                        onChange={e => {
                                            setConfig({width: e.target.value});
                                        }}
                                    />
                                </InputGroup>
                                <InputGroup
                                    style={{cursor: 'pointer', width: '250px', maxWidth: '100%', margin: '10px'}}
                                >
                                    <InputLeftAddon>Height:</InputLeftAddon>
                                    <Input
                                        placeholder="1080"
                                        size="md"
                                        type="number"
                                        value={height}
                                        onChange={e => {
                                            setConfig({height: e.target.value});
                                        }}
                                    />
                                </InputGroup>
                                <InputGroup
                                    style={{cursor: 'pointer', width: '250px', maxWidth: '100%', margin: '10px'}}
                                >
                                    <InputLeftAddon>FPS:</InputLeftAddon>
                                    <Input
                                        placeholder="30"
                                        size="md"
                                        type="number"
                                        value={FPS}
                                        onChange={e => {
                                            setFPS(e.target.value);
                                        }}
                                    />
                                    <InputRightAddon>fps</InputRightAddon>
                                </InputGroup>
                            </Column>
                            <Column
                                sm={`
                                order: -1;
                            `}
                            >
                                <AddFiles
                                    key={folderHandle?.name}
                                    droppedFiles={droppedFiles}
                                    onFilesProcessed={onFilesProcessed}
                                    items={items}
                                    setItems={setItems}
                                    setStatus={setStatus}
                                    setVideoLength={newLength => {
                                        // Only if its 60
                                        console.log('videoLength.current', videoLengthRef.current);
                                        if (
                                            videoLengthRef.current === 0 ||
                                            videoLengthRef.current === '0' ||
                                            typeof videoLengthRef.current === 'undefined' ||
                                            videoLengthRef.current === ''
                                        ) {
                                            setVideoLength(newLength);
                                        }
                                    }}
                                    setDuration={newDur => {
                                        // Only if its 60
                                        console.log('videoLength.current', videoLengthRef.current);
                                        if (
                                            videoLengthRef.current === 0 ||
                                            videoLengthRef.current === '0' ||
                                            typeof videoLengthRef.current === 'undefined' ||
                                            videoLengthRef.current === ''
                                        ) {
                                            setDuration(newDur);
                                        }
                                    }}
                                    FPS={FPS}
                                    folderHandle={folderHandle}
                                    resetFolderHandle={resetFolderHandle}
                                    width={width}
                                    height={height}
                                    videoLength={videoLengthRef}
                                />
                                <hr />
                                <RenderedVideo folderHandle={folderHandle} videoName="output_encoded.mp4" />
                                {/*<TimelineDemo />*/}
                            </Column>
                        </Row>
                        <Row>
                            {/*<VideoTimeline
                                items={items}
                                setItems={setItems}
                                currentTime={currentTime}
                                setCurrentTime={setCurrentTime}
                                videoLength={videoLength}
                                setVideoLength={setVideoLength}
                                FPS={FPS}
                                setFPS={setFPS}
                                width={width}
                                height={height}
                                scale={scale}
                                setScale={newScale => {
                                    setConfig({...config, scale: newScale});
                                }}
                            />*/}
                        </Row>
                    </Column>
                    <Column
                        sm={`
                            order: -1;
                        `}
                    >
                        {canvas}
                        {smallCanvas}
                        <br />
                        <div>
                            {/* Show statusProgress as a chakra progress bar */}
                            {statusProgress > 0 && <ProgressChakra value={statusProgress * 100} />}
                        </div>
                        <InputWrapper>
                            <InputGroup style={{cursor: 'pointer'}}>
                                <InputLeftAddon>Duration: </InputLeftAddon>
                                <Input
                                    placeholder="01:00"
                                    size="md"
                                    type="number"
                                    value={videoLength}
                                    onChange={e => {
                                        /*let time = moment(e.target.value, 'mm:ss').diff(
                                            moment().startOf('day'),
                                            'seconds'
                                        );
                                        if (isNaN(time)) {
                                            time = duration || 60;
                                        }
                                        setVideoLength(time);*/
                                        setVideoLength(e.target.value);
                                    }}
                                />
                                {/* Button to set video length to first audio file */}
                                <Tooltip
                                    label={`Set video length to first audio file: 
                                        ${items.find(item => item.type === 'audio')?.audioFile?.duration || 0}s`}
                                >
                                    <TemporaryButton
                                        active={(() => {
                                            const match = items.find(item => item.type === 'audio');
                                            if (match) {
                                                return videoLength !== match?.audioFile?.duration;
                                            }
                                            return false;
                                        })()}
                                        onClick={() => {
                                            const audioItem = items.find(item => item.type === 'audio');
                                            console.log('audioItem', audioItem);
                                            if (audioItem && audioItem.audioFile && audioItem.audioFile.duration) {
                                                setVideoLength(audioItem?.audioFile?.duration);
                                            }
                                        }}
                                        as={IconButton}
                                        icon={<FaMusic size="0.7em" />}
                                    />
                                </Tooltip>
                            </InputGroup>
                        </InputWrapper>
                        <InputWrapper>
                            {/* updateRate, and flushThreshold */}
                            <InputGroup
                                opacity={0.25}
                                _hover={{opacity: 1}}
                                _focusWithin={{opacity: 1}}
                                style={{transition: 'opacity 0.2s'}}
                            >
                                <InputLeftAddon>Update Rate: </InputLeftAddon>
                                <Input
                                    placeholder="12"
                                    size="md"
                                    type="number"
                                    value={updateRate}
                                    onChange={e => {
                                        setUpdateRate(e.target.value);
                                    }}
                                />
                                <TemporaryButton
                                    active={updateRate !== getDefaultUpdateRate()}
                                    onClick={() => {
                                        setUpdateRate(getDefaultUpdateRate());
                                    }}
                                    as={IconButton}
                                    icon={<FaUndo size="0.7em" />}
                                />
                            </InputGroup>
                            <InputGroup
                                opacity={0.25}
                                _hover={{opacity: 1}}
                                _focusWithin={{opacity: 1}}
                                style={{transition: 'opacity 0.2s'}}
                            >
                                <InputLeftAddon>Flush Threshold: </InputLeftAddon>
                                <Input
                                    placeholder="500"
                                    size="md"
                                    type="number"
                                    value={flushThreshold}
                                    onChange={e => {
                                        setFlushThreshold(e.target.value);
                                    }}
                                />
                                <TemporaryButton
                                    active={flushThreshold !== getDefaultFlushThreshold()}
                                    onClick={() => {
                                        setFlushThreshold(getDefaultFlushThreshold());
                                    }}
                                    as={IconButton}
                                    icon={<FaUndo size="0.7em" />}
                                />
                            </InputGroup>
                        </InputWrapper>
                        <HStack mt={4}>
                            <ButtonGroup>
                                <RenderButton
                                    folderHandle={folderHandle}
                                    fileName="output.mp4"
                                    handleRender={handleRender}
                                />

                                {/*<YoutubeAuth youtubeUploadRef={youtubeUploadRef} />*/}

                                {stopState && (
                                    <Button
                                        onClick={() => {
                                            stopState.stop = true;
                                            setStopState(false);
                                        }}
                                        colorScheme="red"
                                    >
                                        Stop Rendering
                                    </Button>
                                )}
                            </ButtonGroup>
                        </HStack>
                        <hr />
                        <CanvasControls
                            volumeRef={volumeRef}
                            currentTime={currentTime}
                            videoLength={videoLength}
                            isPlaying={isPlaying}
                            setIsPlaying={setIsPlaying}
                            setCurrentTime={setCurrentTime}
                        />
                        <hr />

                        <div>
                            <legend>Status</legend>
                            <AutoScroller style={{maxHeight: '200px', overflow: 'auto'}}>
                                {status.map((s, i) => {
                                    return (
                                        s &&
                                        `${s}`.split('\n').map(s => {
                                            return <HighlightedLog key={s + i} log={s} />;
                                        })
                                    );
                                })}
                            </AutoScroller>
                        </div>
                    </Column>
                </Row>
            </div>
        </>
    );
};

const AutoScroller = ({children, ...props}) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
    }, [children]);

    return (
        <motion.pre ref={ref} {...props}>
            {children}
        </motion.pre>
    );
};

const ProgressBarContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    margin: 10px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    cursor: col-resize;
    user-select: none;
    span {
        position: absolute;
        left: 0;
        font-family: monospace;
        font-size: 12px;
        padding: 5px;
    }
`;
const fallbackSettings = {
    x: 0,
    y: 0,
    rotate: 0,
    scale: 1,
    opacity: 1,
    order: 1000000,
    currentTimeMultiplier: 10000,
    shakeMultiplier: -2,
    smoothness: 0.005,
    loopReverse: false,
    font: mainFont,
    offsetCount: 2,
    spacing: 20,
    opacityOffset: 0,
    fontSize: 20,
    blur: 0
};

const Progress = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #4caf50;
    transition:
        transform 0.1s,
        background 0.1s;
    transform-origin: left;
    transform: scaleX(0);
`;

const ProgressBar = forwardRef(({currentTime, videoLength, children, isPlaying, ...props}, ref) => (
    <ProgressBarContainer ref={ref} {...props}>
        <Progress
            style={{
                background: isPlaying ? '#4CAF50' : '#4CAF5050',
                transform: `scaleX(${currentTime / videoLength})`
            }}
        />
        {children}
    </ProgressBarContainer>
));

const ProgressNumber = ({currentTime, videoLength}) => (
    <span>
        {moment.utc(currentTime * 1000).format('mm:ss')} / {moment.utc(videoLength * 1000).format('mm:ss')}
    </span>
);

const PlayControls = ({volumeRef, isPlaying, setIsPlaying, setCurrentTime}) => (
    <ButtonGroup>
        <IconButton icon={isPlaying ? <FaPause /> : <FaPlay />} onClick={() => setIsPlaying(!isPlaying)} />
        <IconButton icon={<MdStop />} onClick={() => setCurrentTime(0)} />
        <VolumeControls volumeRef={volumeRef} />
    </ButtonGroup>
);

const VolumeControls = ({volumeRef}) => {
    const [volume, setVolume] = useState(() => localStorage.getItem('volume') || 1);

    useEffect(() => {
        volumeRef.current = volume;
        localStorage.setItem('volume', volume);
    }, [volume]);

    return (
        <Popover>
            <PopoverTrigger>
                <IconButton icon={volume > 0.5 ? <FaVolumeUp /> : volume > 0 ? <FaVolumeDown /> : <FaVolumeMute />} />
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Volume</PopoverHeader>
                <PopoverBody>
                    <Slider
                        value={volume}
                        onChange={value => setVolume(value)}
                        min={0}
                        max={1}
                        step={0.01}
                        onDoubleClick={() => setVolume(1)}
                    >
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

const CanvasControlsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    // Fixed at bottom on mobile
    @media (max-width: 768px) {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        z-index: 999;
        backdrop-filter: blur(3px);
    }
`;

const CanvasControls = ({volumeRef, currentTime, videoLength, isPlaying, setIsPlaying, setCurrentTime}) => {
    const ref = useRef(null);
    const [disabled, setDisabled] = useState(false);
    const originalIsPlaying = useRef(isPlaying);
    const mousedown = useRef(false);

    const handleUpdate = e => {
        const rect = ref.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const width = rect.width;
        const percent = x / width;
        setCurrentTime(percent * videoLength);
    };

    return (
        <CanvasControlsContainer>
            <ProgressBar
                ref={ref}
                isPlaying={isPlaying}
                currentTime={currentTime}
                videoLength={videoLength}
                onMouseMove={e => {
                    if (mousedown.current) {
                        !disabled && handleUpdate(e);
                    }
                }}
                onTouchMove={e => !disabled && handleUpdate(e)}
                onMouseEnter={() => (originalIsPlaying.current = isPlaying)}
                onTouchStart={() => (originalIsPlaying.current = isPlaying)}
                onMouseDown={() => {
                    mousedown.current = true;
                }}
                onMouseUp={() => {
                    mousedown.current = false;
                }}
                onClick={e => (handleUpdate(e), setIsPlaying(originalIsPlaying.current), setDisabled(true))}
                onTouchEnd={() => (setIsPlaying(originalIsPlaying.current), setDisabled(false))}
                onMouseLeave={() => (setIsPlaying(originalIsPlaying.current), setDisabled(false))}
            >
                <ProgressNumber currentTime={currentTime} videoLength={videoLength} />
            </ProgressBar>

            <PlayControls
                volumeRef={volumeRef}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                setCurrentTime={setCurrentTime}
            />
        </CanvasControlsContainer>
    );
};

const makeShakeLabel = key => {
    if (key === 'smoothness') {
        key = 'speed';
    }
    return `${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}`;
};

const SettingsGrid = styled(motion.div)`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    margin-bottom: 20px;
`;

const SettingItem = styled(motion.div)`
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(50% - 20px);
`;

const SettingLabel = styled.label`
    font-size: 14px;
    margin-bottom: 5px;
    color: #e0e0e0;
`;

const EditableSettingsValue = ({value, onChange, children, ...props}) => {
    const [val, setVal] = useState(value || children);

    useEffect(() => {
        setVal(value || children);
    }, [value, children]);

    return (
        <Editable
            value={val}
            onChange={setVal}
            onSubmit={() => {
                if (onChange) {
                    onChange({
                        target: {
                            value: val
                        }
                    });
                }
            }}
            {...props}
        >
            <EditablePreview />
            <EditableInput />
        </Editable>
    );
};

const SettingValue = styled(EditableSettingsValue)`
    font-size: 12px;
    color: #a0a0a0;
    margin-top: 5px;
    &:before {
        content: ' ';
    }
    input {
        &,
        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }
`;

const useThrottledCallback = (callback, delay) => {
    const lastRan = useRef(Date.now() - delay);
    return useCallback(
        (...args) => {
            if (Date.now() - lastRan.current >= delay) {
                lastRan.current = Date.now();
                callback(...args);
            }
        },
        [callback, delay]
    );
};

const StyledSliderBase = forwardRef(({className, onChange: onChangeProp, value: valueProp, ...props}, ref) => {
    const onChange = useThrottledCallback(onChangeProp || (() => {}), 100);
    const [value, setValue] = useState(valueProp);

    useEffect(() => {
        onChange({
            target: {
                value
            }
        });
    }, [value]);

    return (
        <motion.input
            ref={ref}
            onDoubleClick={() => {
                const betweenMinAndMax = (props.min + props.max) / 2;
                setValue(betweenMinAndMax);
            }}
            className={className}
            value={value}
            onChange={e => {
                setValue(e.target.value);
            }}
            onWheel={e => {
                e.preventDefault();
                setValue(prev => {
                    const newValue = prev + e.deltaY / 100;
                    return Math.min(props.max, Math.max(props.min, newValue));
                });
            }}
            {...props}
        />
    );
});

const StyledSlider = styled(StyledSliderBase)`
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #4a4a4a;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;

    &:hover {
        opacity: 1;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #4caf50;
        cursor: pointer;
    }

    &::-moz-range-thumb {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #4caf50;
        cursor: pointer;
    }
`;

const ShakeSettings = ({settings, onChange, after}) => {
    const [config] = useFolderHandle();
    return (
        <SettingsGrid initial={{opacity: 0, y: 20}} animate={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
            {['startTime', 'endTime', 'currentTimeMultiplier', 'shakeMultiplier', 'smoothness'].map(key => (
                <SettingItem key={key}>
                    <SettingLabel>{makeShakeLabel(key)}</SettingLabel>
                    <StyledSlider
                        type="range"
                        value={settings[key] ?? fallbackSettings[key]}
                        onChange={e => onChange({...settings, [key]: parseFloat(e.target.value)})}
                        step={0.001}
                        min={getMin(key, config)}
                        max={getMax(key, config)}
                    />
                    <SettingValue onChange={e => onChange({...settings, [key]: parseFloat(e.target.value)})}>
                        {settings[key]?.toFixed(3)}
                    </SettingValue>
                </SettingItem>
            ))}

            {after}
        </SettingsGrid>
    );
};

const Settings = ({settings, onChange}) => {
    const [config] = useFolderHandle();
    const videoLength = config?.duration || 60;
    return (
        <SettingsGrid initial={{opacity: 0, y: 20}} animate={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
            {/* Order */}
            <Input
                type="text"
                value={settings.order || 0}
                onChange={e => onChange({...settings, order: e.target.value})}
                placeholder="Order"
            />

            {['x', 'y', 'rotate', 'scale', 'opacity'].map(key => (
                <SettingItem key={key}>
                    <SettingLabel>{key.charAt(0).toUpperCase() + key.slice(1)}</SettingLabel>
                    <StyledSlider
                        type="range"
                        value={settings[key] ?? fallbackSettings[key]}
                        onChange={e => onChange({...settings, [key]: parseFloat(e.target.value)})}
                        step={0.01}
                        min={key === 'opacity' ? 0 : key === 'scale' ? 0.1 : -3}
                        max={key === 'opacity' ? 1 : key === 'scale' ? 5 : 3}
                        onDoubleClick={() =>
                            onChange({...settings, [key]: key === 'opacity' ? 1 : key === 'scale' ? 1 : 0})
                        }
                    />
                    <SettingValue onChange={e => onChange({...settings, [key]: parseFloat(e.target.value)})}>
                        {settings[key]?.toFixed(2)}
                    </SettingValue>
                </SettingItem>
            ))}
            <SettingItem>
                <SettingLabel>Start Time (s)</SettingLabel>
                <StyledSlider
                    type="range"
                    value={settings.startTime ?? fallbackSettings.startTime}
                    onChange={e => onChange({...settings, startTime: parseFloat(e.target.value)})}
                    step={0.1}
                    min={0}
                    max={settings.endTime ?? fallbackSettings.endTime}
                    onDoubleClick={() => onChange({...settings, startTime: 0})}
                />
                <SettingValue onChange={e => onChange({...settings, startTime: parseFloat(e.target.value)})}>
                    {settings.startTime?.toFixed(1)}s
                </SettingValue>
            </SettingItem>
            <SettingItem>
                <SettingLabel>End Time (s)</SettingLabel>
                <StyledSlider
                    type="range"
                    value={settings.endTime ?? fallbackSettings.endTime}
                    onChange={e => onChange({...settings, endTime: parseFloat(e.target.value)})}
                    step={0.1}
                    min={settings.startTime ?? fallbackSettings.startTime}
                    max={videoLength || fallbackSettings.endTime}
                    onDoubleClick={() => onChange({...settings, endTime: videoLength || fallbackSettings.endTime})}
                />
                <SettingValue onChange={e => onChange({...settings, endTime: parseFloat(e.target.value)})}>
                    {settings.endTime?.toFixed(1)}s
                </SettingValue>
            </SettingItem>
            <SettingItem>
                <SettingLabel>Blur</SettingLabel>
                <StyledSlider
                    type="range"
                    min={0}
                    max={10}
                    value={settings.blur || 0}
                    onChange={e => onChange({...settings, blur: parseInt(e.target.value, 10)})}
                />
            </SettingItem>
        </SettingsGrid>
    );
};

const getMin = (key, config) => {
    /*min={key === 'offsetCount' ? 0 : key === 'spacing' ? 0 : 0}
    max={key === 'offsetCount' ? 10 : key === 'spacing' ? 50 : 30000}*/
    /*min={key === 'smoothness' ? 0 : key === 'shakeMultiplier' ? 0 : 0}
    max={key === 'smoothness' ? 0.04 : key === 'shakeMultiplier' ? 60 : 30000}*/
    if (key === 'offsetCount') {
        return 0;
    } else if (key === 'spacing') {
        return 0;
    } else if (key === 'fontSize') {
        return 10;
    } else if (key === 'smoothness') {
        return 0;
    } else if (key === 'shakeMultiplier') {
        return -60;
    } else if (key === 'startTime') {
        return 0;
    } else if (key === 'endTime') {
        return 0;
    } else {
        return 0;
    }
};

const getMax = (key, config) => {
    if (key === 'offsetCount') {
        return 10;
    }
    if (key === 'spacing') {
        return 50;
    }
    if (key === 'fontSize') {
        return 100;
    } else if (key === 'smoothness') {
        return 0.04;
    } else if (key === 'shakeMultiplier') {
        return 60;
    } else if (key === 'startTime') {
        return config?.duration || 60;
    } else if (key === 'endTime') {
        return config?.duration || 60;
    } else {
        return 30000;
    }
};

// Prev lines count, next lines count, font name
const SrtSettings = ({settings, onChange}) => {
    const [config] = useFolderHandle();
    const videoLength = config?.duration || 60;
    return (
        <>
            {/* Shake */}
            <ShakeSettings
                settings={settings}
                onChange={onChange}
                after={
                    <Button
                        onClick={() => {
                            onChange({
                                ...settings,
                                currentTimeMultiplier: 10000,
                                shakeMultiplier: -2,
                                smoothness: 0.005
                            });
                        }}
                    >
                        Reset to default
                    </Button>
                }
            />
            <SettingsGrid initial={{opacity: 0, y: 20}} animate={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
                {/* Special */}
                {['offsetCount', 'spacing', 'fontSize'].map(key => (
                    <SettingItem key={key}>
                        <SettingLabel>{makeShakeLabel(key)}</SettingLabel>
                        <StyledSlider
                            type="range"
                            value={settings[key] ?? fallbackSettings[key]}
                            onChange={e => onChange({...settings, [key]: parseFloat(e.target.value)})}
                            step={1}
                            min={getMin(key)}
                            max={getMax(key)}
                        />
                        <SettingValue onChange={e => onChange({...settings, [key]: parseFloat(e.target.value)})}>
                            {settings[key]?.toFixed(0)}
                        </SettingValue>
                    </SettingItem>
                ))}

                {/* Opacity offset (to make it faster decay) */}
                <SettingItem>
                    <SettingLabel>Opacity Offset</SettingLabel>
                    <StyledSlider
                        type="range"
                        value={settings.opacityOffset || 0}
                        onChange={e => onChange({...settings, opacityOffset: parseFloat(e.target.value)})}
                        step={0.01}
                        min={0}
                        max={1}
                    />
                    <SettingValue onChange={e => onChange({...settings, opacityOffset: parseFloat(e.target.value)})}>
                        {settings.opacityOffset?.toFixed(2)}
                    </SettingValue>
                </SettingItem>

                {/* font select */}
                {/* Arial, Times New Roman, Courier New, Verdana, Tahoma, Trebuchet MS, Arial Black, Impact, Comic Sans MS, Veteran Typewriter */}
                <SettingItem>
                    {settings.font !== 'Veteran Typewriter' && (
                        <FontPicker
                            defaultValue={settings.font || 'Veteran Typewriter'}
                            localFonts={[
                                {
                                    name: 'Veteran Typewriter',
                                    sane: 'Veteran Typewriter',
                                    cased: 'Veteran Typewriter',
                                    variants: ['1,400']
                                }
                            ]}
                            value={font1 => {
                                onChange({...settings, font: font1});
                                // Font links
                                const fontLinks = document.querySelectorAll(
                                    'link[rel="stylesheet"][href*="fonts.googleapis.com"]'
                                );
                                // Wait for all fonts to load
                                fontLinks.forEach(link => {
                                    link.addEventListener('load', () => {
                                        onChange({
                                            ...settings,
                                            fontRenew: Date.now(),
                                            font: font1
                                        });
                                    });
                                });
                            }}
                            autoLoad={true}
                        />
                    )}
                    <Button
                        mt={2}
                        onClick={() => {
                            onChange({
                                ...settings,
                                font: settings.font === 'Veteran Typewriter' ? 'Open Sans' : 'Veteran Typewriter',
                                fontRenew: Date.now()
                            });
                        }}
                    >
                        {settings.font === 'Veteran Typewriter' ? 'Change font' : 'Reset to default'}
                    </Button>
                </SettingItem>
                {/*<SettingItem>
            <SettingLabel>Font</SettingLabel>
            <select
                value={settings.font || 'Veteran Typewriter'}
                onChange={e => onChange({...settings, font: e.target.value})}
            >
                <option value="Arial">Arial</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Courier New">Courier New</option>
                <option value="Verdana">Verdana</option>
                <option value="Tahoma">Tahoma</option>
                <option value="Trebuchet MS">Trebuchet MS</option>
                <option value="Arial Black">Arial Black</option>
                <option value="Impact">Impact</option>
                <option value="Comic Sans MS">Comic Sans MS</option>
                <option value="Veteran Typewriter">Veteran Typewriter</option>
            </select>
        </SettingItem>*/}
                <SettingItem>
                    <SettingLabel>Start Time (s)</SettingLabel>
                    <StyledSlider
                        type="range"
                        value={settings.startTime ?? fallbackSettings.startTime}
                        onChange={e => onChange({...settings, startTime: parseFloat(e.target.value)})}
                        step={0.1}
                        min={0}
                        max={settings.endTime ?? fallbackSettings.endTime}
                        onDoubleClick={() => onChange({...settings, startTime: 0})}
                    />
                    <SettingValue onChange={e => onChange({...settings, startTime: parseFloat(e.target.value)})}>
                        {settings.startTime?.toFixed(1)}s
                    </SettingValue>
                </SettingItem>
                <SettingItem>
                    <SettingLabel>End Time (s)</SettingLabel>
                    <StyledSlider
                        type="range"
                        value={settings.endTime ?? fallbackSettings.endTime}
                        onChange={e => onChange({...settings, endTime: parseFloat(e.target.value)})}
                        step={0.1}
                        min={settings.startTime ?? fallbackSettings.startTime}
                        max={videoLength || fallbackSettings.endTime}
                        onDoubleClick={() => onChange({...settings, endTime: videoLength || fallbackSettings.endTime})}
                    />
                    <SettingValue onChange={e => onChange({...settings, endTime: parseFloat(e.target.value)})}>
                        {settings.endTime?.toFixed(1)}s
                    </SettingValue>
                </SettingItem>
            </SettingsGrid>
        </>
    );
};

const SelectFolderButton = styled.button`
    padding: 10px 20px;
    background: #4caf50;
    color: white;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    z-index: 999;
    position: relative;
`;

const SelectFolderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
`;

const SelectFolder = forwardRef(({children, ...props}, ref) => (
    <SelectFolderContainer>
        <SelectFolderButton ref={ref} {...props}>
            {children}
        </SelectFolderButton>
    </SelectFolderContainer>
));

const VideoRendererWithDragAndDrop = withDragAndDrop(VideoRenderer);

export default VideoRendererWithDragAndDrop;
