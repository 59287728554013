import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {motion} from 'framer-motion';

const DropZoneContainer = styled(motion.div)`
    position: relative;
    &:after {
        content: '${props => props.dropLabel}';
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        color: white;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: opacity 0.3s;
        pointer-events: none;
    }

    ${props =>
        props.dragging &&
        css`
            &:after {
                opacity: 1;
            }
        `}
`;

/**
 * A higher-order component (HOC) that adds drag-and-drop functionality
 * to the wrapped component.
 *
 * @param {React.Component} WrappedComponent - The component to wrap
 * and enhance with drag-and-drop capabilities.
 *
 * @returns {React.Component} A new component with drag-and-drop functionality.
 */
const withDragAndDrop = WrappedComponent => {
    return props => {
        const [droppedFiles, setDroppedFiles] = useState([]);
        const [dragging, setDragging] = useState(false);

        const handleDrop = async event => {
            if (dragging) {
                setDragging(false);
            } else {
                return;
            }
            event.preventDefault();
            const files = Array.from(event.dataTransfer.files);
            setDroppedFiles(files);
            setDragging(false);
        };

        return (
            <DropZoneContainer
                dragging={dragging || droppedFiles.length > 0}
                dropLabel={droppedFiles.length > 0 ? 'Processing files...' : dragging ? 'Drop files here' : ''}
                onDragEnd={() => setDragging(false)}
                onDragExit={() => setDragging(false)}
                onDragLeave={() => setDragging(false)}
                onDragOver={e => {
                    if (window.currentDrag) {
                        return;
                    }
                    e.preventDefault();
                    setDragging(true);
                }}
                onDrop={handleDrop}
                onMouseLeave={() => setDragging(false)}
            >
                <WrappedComponent {...props} droppedFiles={droppedFiles} onFilesProcessed={() => setDroppedFiles([])} />
            </DropZoneContainer>
        );
    };
};

export default withDragAndDrop;
